import { Helmet } from 'react-helmet-async';
// @mui
import { Grid,  Container, Stack, Typography, Card, Box, Divider, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useGetUser from '../hooks/useGetUser';
import { auth } from '../firebase';

function UpgradePro() {
  const navigate = useNavigate()
  const user = useGetUser(auth.currentUser?.uid)
  return (
    <>
      <Helmet>
        <title> Upgrade to Premium</title>
      </Helmet>

      <Container>
 

      <Stack spacing={3} justifyContent={"center"} alignItems={"center"}> 
          <Typography variant='h3' sx={{textAlign:"center"}}>Flexible plans <br/>for your business needs.<br/>
      <Typography component={"span"}>
      Choose your plan to make your business more visible to your clients
      </Typography>
      </Typography>
      </Stack>
        
        <Grid container spacing={3} sx={{mt:3}}>
          <Grid item xs={12} md={4}>
          <Card sx={{p:4}}>
          <Box>
            
            <Typography variant='h5'>Free</Typography>
            <Typography variant='overline'>Forever</Typography>
            
            <Typography variant='h3'>0</Typography>
            <Divider sx={{my:6}} >Features</Divider>
            <Typography variant='body1'>1 Business</Typography><br/>
            <Typography variant='body1'>1 Album</Typography><br/>
            <Typography variant='body1'>1 Event</Typography><br/>
            <Typography variant='body1'>5 images</Typography><br/>
             <Divider sx={{my:6}} >Select package</Divider>
            {user?.account === "FREE" ? <Typography variant='body1'>Current Package</Typography> : 
            <Button variant="contained" color="warning" onClick={()=>navigate("/dashboard/payment", {state : {package : "FREE", bill : 0}})} fullWidth>Choose Free</Button>}
          </Box>
          </Card>
          </Grid>
          <Grid item xs={12} md={4}>
          <Card sx={{p:4}}>
          <Box>
            
            <Typography variant='h5'>Basic</Typography>
            <Typography variant='overline'>Yearly
            </Typography>
           
            <Typography variant='h3'>K57</Typography>
            <Divider sx={{my:6}} >Features</Divider>
            <Typography variant='body1'>3 Business</Typography><br/>
            <Typography variant='body1'>3 Albums</Typography><br/>
            <Typography variant='body1'>3 Events</Typography><br/>
            <Typography variant='body1'>10 Images (per album)</Typography><br/>
            <Divider sx={{my:6}} >Select package</Divider>
            {user?.account === "BASIC" ? <Typography variant='body1'>Current Package</Typography> : 
            <Button variant="contained" color="warning"  onClick={()=>navigate("/dashboard/payment", {state :{package : "BASIC", bill : 57}})} fullWidth>Choose Basic</Button>}
          </Box>
          </Card>
          </Grid>
          <Grid item xs={12} md={4}>
          <Card sx={{p:4}}>
          <Box>
            
            <Typography variant='h5'>Premium</Typography>
            <Typography variant='overline'>Yearly </Typography>
            
            <Typography variant='h3'>K109</Typography>
            <Divider sx={{my:6}} >Features</Divider>
            <Typography variant='body1'>Unlimited Business</Typography><br/>
            <Typography variant='body1'>Verified Account</Typography><br/>
            <Typography variant='body1'>Unlimited Albums</Typography><br/>
            <Typography variant='body1'>Unlimited images per album</Typography><br/>
            <Typography variant='body1'>Verified Badge</Typography>
             <Divider sx={{my:6}} >Select package</Divider>
            {user?.account === "PRO" ? <Typography variant='body1'>Current Package</Typography> : 
            <Button variant="contained" color="warning"  onClick={()=>navigate("/dashboard/payment", {state : {package :"PRO", bill : 109}})} fullWidth>Choose Premium</Button>}
          </Box>
          </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default UpgradePro