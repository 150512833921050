const vendorCategories = [
  {
    "category": "Coffee Shops",
    "description": "Cozy places to enjoy your favorite coffee beverages.",
    "color": "#A52A2A"
  },
  {
    "category": "Bars & Entertainment",
    "description": "Places to unwind, enjoy drinks, and entertainment.",
    "color": "#FFD700"
  },
  {
    "category": "Wedding & Event Venues",
    "description": "Services for special occasions and social gatherings.",
    "color": "#FF69B4"
  },
  {
    "category": "Liquor Stores",
    "description": "Shops specializing in alcoholic beverages.",
    "color": "#8B4513"
  },
  {
    "category": "Restaurants",
    "description": "Dining establishments offering various cuisines.",
    "color": "#32CD32"
  },
  {
    "category": "Lodges/Hotels",
    "description": "Accommodations for travelers with comfortable amenities.",
    "color": "#4169E1"
  }, 
  {
    "category": "Travel Agencies",
    "description": "Agencies offering travel planning and booking services.",
    "color": "#20B2AA"
  },
  {
    "category": "Play Parks",
    "description": "Parks designed for recreational activities and play.",
    "color": "#32CD32"
  },
  {
    "category": "Kids Birthday Party",
    "description": "Services for organizing fun and memorable birthday parties for kids.",
    "color": "#FF69B4"
  },
  {
    "category": "Wildlife Adventure",
    "description": "Exciting adventures in nature to explore wildlife.",
    "color": "#228B22"
  },
  {
    "category": "Beaches and Resorts",
    "description": "Sandy shores and luxurious resorts for relaxation.",
    "color": "#FFD700"
  },
  {
    "category": "Swimming Pools",
    "description": "Facilities for swimming and aquatic activities.",
    "color": "#00BFFF"
  },
  {
    "category": "Ice Cream & Smoothies",
    "description": "Shops offering delicious frozen treats and smoothies.",
    "color": "#FF4500"
  },
  {
    "category": "Art Gallery & Cultural Ornaments",
    "description": "Galleries displaying artistic creations and cultural artifacts.",
    "color": "#800080"
  },

  {
    "category": "Hair Salons",
    "description": "Salons providing hair styling and treatments.",
    "color": "#FF4500"
  },
  {
    "category": "Barbershop",
    "description": "Shops offering haircut and grooming services for men.",
    "color": "#000080"
  },
  {
    "category": "Spa/Massage",
    "description": "Relaxing spas and massage centers for wellness.",
    "color": "#9932CC"
  },
  {
    "category": "Dry Cleaning",
    "description": "Cleaning services for garments and textiles.",
    "color": "#4B0082"
  },
  {
    "category": "Men & Ladies Clothing",
    "description": "Stores offering fashionable clothing for men and women.",
    "color": "#800000"
  },
  {
    "category": "Children's Clothing",
    "description": "Clothing stores specializing in kids' apparel.",
    "color": "#FF69B4"
  }, 
  {
    "category": "Museums",
    "description": "Institutions showcasing artifacts and historical items.",
    "color": "#A52A2A"
  },
  {
    "category": "Car Wash",
     "color": "#A52A2A"
  },
  {
    "category": "MakeUp Artists",
     "color": "#A52A2A"
  },
  {
    "category": "Cosmetics",
     "color": "#A52A2A"
  },
  {
    "category": "Skin Care",
     "color": "#A52A2A"
  },
  {
    "category": "Gift &  Flower Bouquets",
     "color": "#A52A2A"
  },
  {
    "category": "Cakes & Bakes",
     "color": "#A52A2A"
  },
  {
    "category": "Car Fitment Centre",
     "color": "#A52A2A"
  },
  {
    "category": "Photo Studios",
     "color": "#A52A2A"
  },
  {
    "category": "Food & Beverages",
     "color": "#A52A2A"
  },
  {
    "category": "Dance Studio",
     "color": "#A52A2A"
  },
  {
    "category": "Gaming Centres",
     "color": "#A52A2A"
  },
  {
    "category": "Natural Health & Wellness",
     "color": "#A52A2A"
  },
  {
    "category": "Other",
     "color": "#A52A2A"
  }, 
    { "category": "Airports", "color": "#A52A2A" },
    { "category": "Agro Shops", "color": "#A52A2A" },
    { "category": "Apartments", "color": "#A52A2A" },
    { "category": "Book Stores", "color": "#A52A2A" },
    { "category": "Bureau", "color": "#A52A2A" },
    { "category": "Bus stations", "color": "#A52A2A" },
    { "category": "Butchery", "color": "#A52A2A" },
    { "category": "Car Ports", "color": "#A52A2A" },
    { "category": "Car Dealerships", "color": "#A52A2A" },
    { "category": "Cinemas", "color": "#A52A2A" }, 
    { "category": "Concert venues", "color": "#A52A2A" },
    { "category": "Construction", "color": "#A52A2A" }, 
    { "category": "Drilling Companies", "color": "#A52A2A" },
    { "category": "Electronics & Gadgets", "color": "#A52A2A" },
    { "category": "Fruit & Veg Markets", "color": "#A52A2A" },
    { "category": "Home Goods", "color": "#A52A2A" },
    { "category": "Government Offices", "color": "#A52A2A" },
    { "category": "Grocery stores", "color": "#A52A2A" },
    { "category": "Gyms & Fitness", "color": "#A52A2A" },
    { "category": "Hardware stores", "color": "#A52A2A" },
    { "category": "Influencers", "color": "#A52A2A" },
    { "category": "Jewellery", "color": "#A52A2A" }, 
    { "category": "Libraries", "color": "#A52A2A" },
    { "category": "Music Venues", "color": "#A52A2A" },
    { "category": "Parks and Recreational", "color": "#A52A2A" },
    { "category": "Pharmacies", "color": "#A52A2A" },
    { "category": "Print Shops", "color": "#A52A2A" }, 
    { "category": "Repair shops", "color": "#A52A2A" },
    { "category": "Schools", "color": "#A52A2A" }, 
    { "category": "Shopping Malls", "color": "#A52A2A" },
    { "category": "Stadiums", "color": "#A52A2A" },
    { "category": "Tailoring & Design", "color": "#A52A2A" },
    { "category": "Takeaways", "color": "#A52A2A" },
    { "category": "Taxi Services", "color": "#A52A2A" },
    { "category": "Theaters", "color": "#A52A2A" },
    { "category": "Train stations", "color": "#A52A2A" },
    { "category": "Truck Inns", "color": "#A52A2A" },
    { "category": "Uniforms & Works Suits", "color": "#A52A2A" }, 
    { "category": "Banks", "color": "#A52A2A" }, 
    { "category": "Microfinance", "color": "#A52A2A" }, 
    { "category": "Property Managers", "color": "#A52A2A" },
    { "category": "Real Estate Agents", "color": "#A52A2A" },
    { "category": "AirBnB", "color": "#A52A2A" },
    { "category": "Entertainment", "color": "#A52A2A" },
    { "category": "Logistics", "color": "#A52A2A" }, 
    { "category": "Paint Stores", "color": "#A52A2A" },
    {"category": "ATMs"},
    {"category": "Chemists"},
    {"category": "Chigayo"},
    {"category": "Clinics"},
    {"category": "Colleges"},
    {"category": "Dental clinics"},
    {"category": "Driving Schools"},
    {"category": "Embassies"},
    {"category": "Fire Stations"},
    {"category": "Golf courses"},
    {"category": "Hospitals"},
    {"category": "National Parks"},
    {"category": "NGOs"},
    {"category": "Pet Shops"},
    {"category": "Police Stations"},
    {"category": "Post Office"},
    {"category": "Sports Centre"},
    {"category": "Seedlings"},
    {"category": "Supermarkets"},
    {"category": "Tattoo Studio"},
    {"category": "Universities"},
    {"category": "Zoos"},
    {"category": "Chiropractic Clinics"},
    {"category": "Garden Tools"},
    {"category": "Phones & Laptops"},
    {"category": "Office Supplies"},
    {"category": "Home Appliances"},

]



  
  export default vendorCategories.sort((a, b) => a.category.localeCompare(b.category))