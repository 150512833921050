import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { Button, Divider } from '@mui/material'
import React, {useState} from 'react'
import { collection, doc, getDoc, query, setDoc, where } from 'firebase/firestore'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { auth, db } from '../../../firebase'

function GoogleSignIn() {
  const navigate = useNavigate()
  const provider = new GoogleAuthProvider()

  const [loading, setloading] = useState(false)
  const signIn = () =>{ 
    setloading(true)
    signInWithPopup(auth, provider).then((result)=>{

      const credential = GoogleAuthProvider.credentialFromResult(result)
      const token = credential.accessToken
      const user = result.user
      createUser(user.uid, user.displayName, user.email, user.photoURL )
      console.log(user, token)
    }).catch((error)=>{
      const errorCode = error.code
      const errorMessage = error.message
      const email = error.customData.email
      const credential = GoogleAuthProvider.credentialFromError(error)
      console.log(credential, errorMessage)
    })
  }

  const createUser = async (userId, name, email, photo)  =>{
    const currentDate = Date.now(); 
    const asd =await getDoc(doc(db, "app_users", userId))    
    console.log(asd)     
          if (asd.exists()) {
        
            navigate("/")
            // User data exists, do something
          } else {
            // User data does not exist, navigate to registration page
            setDoc(doc(db, 'app_users', userId), {
               fullName : name,
               phoneNumber : null,
               email,
               profile_image : photo,
              createdAt: currentDate,
              account : "FREE",
              account_status : "APPROVED",
              duration: 365,
              accountUpdatedOn :`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`
            })
              .then(() => {
                console.log('Added data to the database');
                navigate("/")
                setloading(false)
                
              })
              .catch((e) => console.log(e));
          }
  }

  return (
    <div>
     <Divider>Continue with Google</Divider><br/>
    <LoadingButton loading={loading} color='warning' size="large" fullWidth variant='outlined' onClick={()=>signIn()}>Google Sign In</LoadingButton></div>
  )
}

export default GoogleSignIn