import { Button, Card, Container, TextField, Grid, Typography, Stack, Box, Stepper, Step, StepLabel, CircularProgress } from '@mui/material'
import { addDoc, arrayUnion, collection, doc, updateDoc } from 'firebase/firestore'
import React, {useState} from 'react'
import {getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable} from 'firebase/storage'
import Dropzone from 'react-dropzone'
import { useLocation, useNavigate } from 'react-router-dom'
import { auth, db } from '../../../firebase'

function CreateSite({id}) {
    const location = useLocation()
    const [post, setpost] = useState("")
    const [description, setdescription] = useState("")
    const [images, setImages] = useState([]);
    const [cover, setcover] = useState([])
    const data = location.state
    const [loading, setloading] = useState(false)
    const [docId, setdoc] = useState(null)
    const storage = getStorage()
    const [uploadProgress, setUploadProgress] = useState(0);

    const createSite = async () =>{            
      if (cover.length === 0) {
        alert("select an image to upload")
           return;
      }    
        const dat = {
            businessId : data,
            title : post, 
            description
        }
        const docRef = await addDoc(collection(db, `sites`),  {...dat,  userId : auth.currentUser.uid, createdAt : Date.now()});
        uploadImage(docRef.id)        
    }

    const uploadImage = async (docId) => {  
        const image = cover[0]  
        const id = Date.now()
          const storageRef = ref(storage, `businessSite/${image.name}_${id}`);
    
          uploadBytes(storageRef, image).then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot);
            getDownloadURL(snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL);
                const userDocRef = doc(db, 'sites', docId);
                
                        await updateDoc(userDocRef, {
                        coverImage: downloadURL,
                        });
              });
              setloading(false)
              setdoc(docId)
          });
    };  


    const handleMultipleImageChange = () => {
        if (images && images.length > 0) { 
          const selectedImages = Array.from(images);       
          if (selectedImages.length > 5) { 
            const firstTenImages = selectedImages.slice(0, 5);
            uploadImages(firstTenImages) 
          } else {
            uploadImages(selectedImages) 
          }
        }
      };
  
  
      const uploadImages = async (images) => {
    setloading(true) 
    const id = Date.now()
      const storageRef = ref(storage, `businessSite/${docId}_${id}/`); // Define the storage reference
      // eslint-disable-next-line no-async-promise-executor
       const uploadPromises = images.map((image) => new Promise(async (resolve) => {
           const fileRef = ref(storageRef, image.name);
           const uploadTask = uploadBytesResumable(fileRef, image); 
           uploadTask.on('state_changed',
             (snapshot) => {
               const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
               setUploadProgress(Math.round(progress)) 
             },
             (error) => {
               console.error(`Error uploading ${image.name}: ${error.message}`);
               resolve(null);
             },
             () => {
               // Upload completed successfully
               getDownloadURL(fileRef).then(async (downloadURL) => {
                 console.log(`File ${image.name} available at ${downloadURL}`);
                  const userDocRef = doc(db, 'sites', docId );
                 await updateDoc(userDocRef, {
                   images: arrayUnion(downloadURL),
                 });
                   resolve(downloadURL);
               });
             }
           );
         }));
     
       try {
         const uploadedImageUrls = await Promise.all(uploadPromises);
         console.log('All images uploaded:', uploadedImageUrls);
         setImages([]); // Clear the uploaded images
     
          } catch (error) {
         console.error('Error uploading images:', error);
          
       }
    }      
  
  return (
    <Container>
    <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant='h6'>Create Site</Typography>
       
    </Stack>
        <br/>

        <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
        <Stack>
        <Dropzone onDrop={files => setcover(files)}>
        {({getRootProps, getInputProps}) => (
          <div className="container">
            <div
              {...getRootProps({
                className: 'dropzone',
                onDrop: event => event.stopPropagation()
              })}
            >
              <input {...getInputProps()} />
              <Typography>Select the cover image</Typography>
            </div>
            <br/>
          </div>
        )}
      </Dropzone>
        <TextField fullWidth type="text" label="Title" placeholder='Title' value={post}  onChange={(event) => setpost(event.target.value)}                        />
        <br/>  
        <TextField fullWidth type="text" label="Description" multiline rows={3} placeholder='Explain your project in a few words' value={description}  onChange={(event) => setdescription(event.target.value)}                        />
        <br/>
        <Button color='success' variant='contained' size="large" onClick={()=>createSite()}>Save</Button>


    <br/><br/> 
       {docId && 
       <>
       <Dropzone onDrop={files => setImages(files)}>
        {({getRootProps, getInputProps}) => (
          <div className="container">
            <div
              {...getRootProps({
                className: 'dropzone',
                onDrop: event => event.stopPropagation()
              })}
            >
              <input {...getInputProps()} multiple />
              <Typography>Click here to select images</Typography>
            </div>
            <br/>
          </div>
        )}
      </Dropzone>
      <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />
       <Button color='success' variant='contained' size="large" onClick={()=>handleMultipleImageChange()}>Upload Images</Button>
       </>
      }

        </Stack>
      

        </Grid>
            <Grid item xs={12} md={8} sx={{}}>
                <Card sx={{width:"100%", alignItems:"center", height:"100%", p:10, justifyContent:"center", display:'flex'}}>
              <Typography variant='body1'>Preview</Typography>
              
              {/* <img src={cover} alt="cover" /> */}
                </Card>
            </Grid> 
        </Grid>
    </Container>
  )
}

export default CreateSite