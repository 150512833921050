import { LoadingButton } from '@mui/lab';
import { AppBar, Toolbar, Typography, Button, Stack, TextField, Divider, CircularProgress, Card, Box } from '@mui/material'
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import { auth, db } from '../../../firebase';

function SendMessage({handleClose, data, busId}) {
console.log(data, busId)
    const storage = getStorage()
    const [post, setpost] = useState("")
    const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);

    
      const handleUpload = (id , state) => {
        if (post.trim() === "") {
          alert("Write something to continue");
          return;
        }
        uploadPost(id, state);
        // if (images.length > 0) {
        //   uploadImage(images[0], id);
        // } else {
      
        // }
      };

      const uploadPost = async (id, state) => {
        setloading(true)
        // Create a new post document
        const postDoc = {
          post,
          createdAt: Date.now(),
          businessId : data, 
          businessOwner : busId,
          userId: auth.currentUser.uid,
          status : "UNREAD",
          lastUpdate : Date.now()
        }; 
         
     if(!state){
        const timeDate = Date.now()
        await setDoc(doc(db, "inbox", id), {
             createdAt: timeDate,
            businessId : data, 
            businessOwner : busId,
            userId: auth.currentUser.uid,
            lastUpdate : timeDate,
            status : "UNREAD"
        }); 
        setloading(false)
        setpost("")
        await addDoc(collection(db, "inbox", id, "messages"), postDoc); 
        setloading(false)
        setpost("")
      }
      else{
        const timeDate = Date.now()
        await updateDoc(doc(db, "inbox", id), { 
            lastUpdate : timeDate
        }); 
        await addDoc(collection(db, "inbox", id, "messages"), postDoc); 
        setloading(false)
        setpost("")
      }    
      };  
      
   
        const uploadImage = async (image , docId) => {  
          setloading(true)
          if(image){
            const id = Date.now();
  
            const fileRef = ref(storage, `inbox/${image.name}_${id}`);
            const uploadTask = uploadBytesResumable(fileRef, image);
          // Listen to state_changed event to track progress
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(progress)
              setUploadProgress(progress);
            },
            (error) => {
              console.error(`Error uploading ${image.name}: ${error.message}`); 
            },
            () => {
              // Upload completed successfully
              getDownloadURL(fileRef).then(async (downloadURL) => {


                await addDoc(collection(db, "inbox", docId,"messages"), {
                    post,     createdAt: Date.now(),
          businessId : data, 
          businessOwner : busId,
          userId: auth.currentUser.uid,
          status : "UNREAD"
                });
                });
                setpost("")
                setImages([])
                setUploadProgress(0)
                setloading(false)
                handleClose()
            }
          ); }
      }  
     
      const testing = async () =>{
            const bisId = data
            const userId = auth.currentUser?.uid
            const id = `${bisId}_${userId}_${busId}`
            const chatId = doc(db, "inbox", id)
            const docSnap = await getDoc(chatId)
 
            if(docSnap.exists()){
                console.log("chat exists")
                handleUpload(id, true)
               handleClose()
            }
            else{
                  console.log("chat is new")
            handleUpload(id, false)
               handleClose()
            }

      }


  return (
    <div>
        

        <Card sx={{p:3}}>
    <Box>    
        <Stack      >
        <Typography>Send Message </Typography><br/>
        <TextField
        fullWidth rows={3} multiline
         type="post"
        label="Share your message"
        value={post}
        onChange={(event) => setpost(event.target.value)}
      />

  {uploadProgress > 0 && <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />}
    <Stack  sx={{mt:2}} justifyContent={"space-between"}>
    {/* <Dropzone onDrop={files => setImages(files)}>
                {({getRootProps, getInputProps}) => (
                  <div className="container">
                    <div
                      {...getRootProps({
                        className: 'dropzone',
                        onDrop: event => event.stopPropagation()
                      })}
                    >
                      <input {...getInputProps()} />
                      <p>Click to select files</p>
                    </div>
                    </div>
                )}
              </Dropzone> */}
  
      <LoadingButton loading={loading}   variant="contained" onClick={()=>testing()} color="success">Send</LoadingButton>
    </Stack>
        </Stack>
        </Box>
    </Card>

    </div>
  )
}

export default SendMessage