import { Container, Grid, Typography } from '@mui/material'
import React, {useState, useEffect} from 'react'
import MiniSearch from 'minisearch'; 
import { useLocation } from 'react-router-dom'
import useGetDirectory from '../hooks/useGetDirectory';
import ShopProductCard from '../sections/@dashboard/products/ProductCard';

function SearchResults({results, term}) {
  console.log(results, "search")
//     const location = useLocation() 

//     const results = useGetDirectory()?.filter((d)=>d.status === "APPROVED") ;

//     const miniSearch = new MiniSearch({
//       fields: ['name', 'address', 'province', 'town', 'township', 'category', 'tags'],
//       storeFields: ['name', 'address', 'province', 'category','town', 'township', 'cover_image', "userId","createdAt", "id", "location", "phone", "tags",  ],
//       fuzzy: true,
//     });
    
//       if (results) {
//       miniSearch.addAll(results);
//     }
 
//     const [searchQuery, setSearchQuery] = useState('');
//     const [searchResults, setSearchResults] = useState(null);
  
//     const handleSearch = (query) => {
//       setSearchQuery(query);
//       const results = miniSearch.search(query);
//       setSearchResults(results);
//     };
    
//     useEffect(() => {
//           handleSearch(location.state)
        
//     }, [location.state, results])
    

// console.log(searchResults)
  return (
    <Container>
    <Typography variant='h6'>Search for "{term}", ({results?.length} results found)</Typography><br/>
    <Grid container spacing={3} >
      {results?.length === 0 ? 
        <Grid item xs={12} sm={6} md={4}>
      <Typography>No results found</Typography>
      </Grid> : results?.map((product) => (
        <Grid key={product.id} item xs={12} sm={6} md={3}>
          <ShopProductCard product={product} />
        </Grid>
      ))}
    </Grid>
</Container>
  )
}

export default SearchResults