import { Helmet } from 'react-helmet-async'; 
import { AppBar, Box, Button,  Card,  Container, Dialog, Divider, Grid, MenuItem, Stack, TextField, Toolbar, Typography } from '@mui/material';
import { Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { addDoc, collection } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import {  db } from '../../../firebase';

function AddExpense({projectId, handleClose}) {
    const [loading, setloading] = useState(false)
console.log(projectId)
    const expenseCategories = [
        'Materials (e.g., lumber, concrete, steel)',
        'Labor (e.g., construction workers, contractors)',
        'Permits and Fees (e.g., building permits, inspection fees)',
        'Equipment Rental (e.g., excavators, cranes)',
        'Architectural and Design Fees',
        'Engineering Services',
        'Land Purchase',
        'Excavation and Site Preparation',
        'Foundation and Footings',
        'Structural Framing',
        'Roofing and Siding',
        'Windows and Doors',
        'Plumbing',
        'Electrical',
        'HVAC (Heating, Ventilation, and Air Conditioning)',
        'Insulation and Drywall',
        'Flooring and Tiling',
        'Cabinetry and Countertops',
        'Painting and Finishing',
        'Landscaping and Outdoor Structures',
        'Interior Fixtures and Appliances',
        'Security Systems',
        'Waste Removal and Dumpster Rental',
        'Insurance',
        'Quality Control and Inspection',
        'Consulting and Professional Services',
        'Transportation and Shipping',
        'Utilities Installation (e.g., water, gas, electricity)',
        'Cleaning Services',
        'Safety Equipment and Gear',
        'Miscellaneous',
    ];
    const initialValues = {      
        projectId: "",
        expenseCategory : "",
        description : "",
        supplier : "",
        amount : "",
        expenseDate : "",
        durationInDays : ""
      };
    
      const validationSchema = Yup.object().shape({
        expenseCategory: Yup.string().required("Category is required"),
           description: Yup.string().required("Description is required"),
           supplier: Yup.string().required("Supplier is required"),
           amount: Yup.string().required("Amount is required"),
           expenseDate: Yup.string().required("Expense Date Date is required"),
      });  

  return (
    
    <Stack mb={5}>
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
          
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Add Expense to your project
            </Typography>
             
          </Toolbar>
        </AppBar>
    <Formik  initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values,) => {
                        setloading(true)
                    console.log(values);
                    const docRef = await addDoc(collection(db, "projects", projectId, "expenses"),  {...values,  projectId,  createdAt : Date.now()});                      
                        console.log("Document written with ID: ", docRef.id);
                        setloading(false)
                        handleClose()
                                }}
                >
                    {(formik) => (
                    <Form>
                    <Stack spacing={2} sx={{p:3}} > 
           
<Field
                         
                         as={TextField}
                         name="expenseCategory"
                         select fullWidth
                         label="Expense Category"
                         error={formik.touched.expenseCategory && Boolean(formik.errors.expenseCategory)}
                         helperText={formik.touched.expenseCategory && formik.errors.expenseCategory}
                         > 
                         {expenseCategories.map((c, index)=>(
                         <MenuItem value={c} key={index}>{c}</MenuItem>
                 ))}
                         </Field>  

                        <Field                             
                        as={TextField}
                        name="description"              
                        label="Description" fullWidth
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                        />                            
                        <Field                             
                        as={TextField}
                        name="supplier"              
                        label="Supplier" fullWidth
                        error={formik.touched.supplier && Boolean(formik.errors.supplier)}
                        helperText={formik.touched.supplier && formik.errors.supplier}
                        />                            
              
                 <Field                           
                         as={TextField}
                         type="number"
                         name="durationInDays"     fullWidth          
                         label="Duration in days"
                         error={formik.touched.durationInDays && Boolean(formik.errors.durationInDays)}
                         helperText={formik.touched.durationInDays && formik.errors.durationInDays}
                         />                            
                 <Field                           
                         as={TextField}
                         type="number"
                         name="amount"     fullWidth          
                         label="Amount Spent"
                         error={formik.touched.amount && Boolean(formik.errors.amount)}
                         helperText={formik.touched.amount && formik.errors.amount}
                         />                            
                 <Field                           
                         as={TextField}
                         type="date"
                         name="expenseDate"  fullWidth             
                         label="Expense Date"
                         error={formik.touched.expenseDate && Boolean(formik.errors.expenseDate)}
                         helperText={formik.touched.expenseDate && formik.errors.expenseDate}
                         />                            
            
                
                            {loading ? <Typography>Saving project</Typography> : <>
                            <LoadingButton loading={loading} variant='contained'  fullWidth size={"large"} color="success" disabled={!formik.isValid} type='submit'>Save</LoadingButton> 
                               </>}
                            </Stack>
                        </Form>
                    )}
                        </Formik>

    </Stack>

  )
}

export default AddExpense