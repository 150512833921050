import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Button, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import ShopProductCard from './ProductCard';

import useResponsive from '../../../hooks/useResponsive';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};
 
export default function ProductList({ products, vendorCategories, ...other  }) {
 
  const [selected, setselected] = useState(null)
  const isDesktop = useResponsive('up', 'lg');
  const [filtered, setfiltered] = useState([])

   
  function groupContactsByFirstLetter(contacts) {
    const groupedContacts = {};  
    contacts.forEach(contact => {
      const firstLetter = contact.name.charAt(0).toUpperCase(); // Get the first letter and convert to uppercase
  
      if (!groupedContacts[firstLetter]) { 
        groupedContacts[firstLetter] = [];
      }   
      groupedContacts[firstLetter].push(contact);
    });  
    return groupedContacts;
  }
 
  const filterResultsByCategory = (cat) =>{
    setselected(cat)

    const filteredItems = products?.filter(item => item.category === cat);
     
  const groupedContacts =  groupContactsByFirstLetter(filteredItems);
    setfiltered(groupedContacts)

  } 
  return (
    <Grid container spacing={2} {...other}>
    <Grid item xs={12} md={12}>
    <Stack direction="row" flexWrap={"wrap"}>
    {isDesktop ? vendorCategories?.sort((a, b) => a.category.localeCompare(b.category)).map((c, index)=>(
      <Button key={index}  onClick={()=>filterResultsByCategory(c.category)} variant={selected === c.category? "contained" : "outlined"} sx={{m:1}}  color='warning' >{c.category}</Button>

    ))
     :
     <TextField fullWidth label="Category"   select  value={selected}
           onChange={(event) => filterResultsByCategory(event.target.value)}
          >
          {vendorCategories?.sort((a, b) => a.category.localeCompare(b.category)).map((c, index)=>(
                            <MenuItem value={c.category} key={index}><Stack>
                                <Typography>{c.category}</Typography>
                            </Stack></MenuItem>
                    ))}
          </TextField>
     }
 
    </Stack>
    </Grid>

      {selected !== null &&  Object.keys(filtered).sort().map((product, index) => (
        <>
        <Typography variant='h5' sx={{m:2}}>{product}</Typography>
        <Grid container key={index} >
              {filtered[product].map((p, index)=>(
                <Grid item key={index} sx={{m:2}} xs={12} md={3}>
                <ShopProductCard product={p}  />
                </Grid>
              ))}
        </Grid>
        </>
      )) } 
        
      {selected === null &&   
      products.sort().map((product, index) => (
         <Grid item key={index}  xs={12} md={4} lg={3}>
                    <ShopProductCard product={product}  />
     </Grid>
          ))
        
        }
        
      
    </Grid>
  );
}
