import {useEffect, useState } from 'react';
 
// @mui
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Card, Grid, Button, Typography, CardContent, ButtonGroup, Divider, AvatarGroup, Avatar } from '@mui/material';
import { addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { auth, db } from '../../../firebase';
import UserName from '../../../_mock/UserName';
import { fToNow } from '../../../utils/formatTime';
// utils


export default function BlogPostCard({ post, index }) {

  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [dislikeCount, setDislikeCount] = useState(0);
  const [users, setusers] = useState([])
const navigate = useNavigate()

 

  useEffect(() => {
    // Query Firestore for the current user's like/dislike status for the item
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userQuery = query(collection(db, 'postFeedback'), where('postId', '==', post.id), where('userId', '==', userId));
    onSnapshot(userQuery, (userSnapshot) => {
        if (!userSnapshot.empty) {
         
          setIsLiked(userSnapshot.docs[0].data().is_liked);
        }
      });
    }
  
    // Query Firestore for the total number of likes and dislikes for the item
    const itemQuery = query(collection(db, 'postFeedback'), where('postId', '==', post.id));
    const itemQueryUnsubscribe = onSnapshot(itemQuery, (itemSnapshot) => {
      let likeCount = 0;
      let dislikeCount = 0;
   
      itemSnapshot.forEach((doc) => {
        if (doc.data().is_liked) {
          
          likeCount += 1;
        } else {
          dislikeCount +=1;
        }
      });
      
      setLikeCount(likeCount);
      setDislikeCount(dislikeCount);
    });
  
    // Clean up listeners when the component unmounts
    return () => {
      if (itemQueryUnsubscribe) itemQueryUnsubscribe(); 
    };
  }, [post]);

  const helpful = async (id, status)=>{
    const userId = auth.currentUser?.uid;

    // Create a Firestore query to check if the user has already liked/disliked the item
    const q = query(collection(db, 'postFeedback'), where('postId', '==', id), where('userId', '==', userId));
  
    try {
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        // User has already liked/disliked the item, update the existing document
        const doc = querySnapshot.docs[0];
        const isLiked = !doc.data().is_liked;
        await updateDoc(doc.ref, { is_liked: status });
        setIsLiked(isLiked);
      } else {
        // User has not yet liked/disliked the item, create a new document
       await addDoc(collection(db, 'postFeedback'), {
          postId: id,
          userId,
          is_liked: status,
          count: 1,
        });
        setIsLiked(true);
      }
    } catch (error) {
      console.error('Error handling like click:', error);
    }
  }

  const deleteBlogItem = async () =>{
    await deleteDoc(doc(db, "posts", post.id)).then(()=>{
      alert("Blog deleted successfully")
    }).catch((e)=>{
      alert(e)
    })
  }

  
  return (

     <Grid item xs={12} md={12} key={index}>
              <Card sx={{p:3, mb:2}} >
                <Box>
                  <Stack onClick={()=>navigate("/dashboard/viewBlogPost", {state : post})}>
                    <UserName id={post.userId} />
                    <Typography variant='body1' sx={{mb:2}}>{post.post}</Typography>
                    {post.image && <img src={post.image} alt='loading'  />} 
                  </Stack>
                    <Stack direction="row" justifyContent={"space-between"}>
                    <Typography component='overline' sx={{fontSize:12, mt:3}}>{fToNow(post.createdAt)}</Typography>
                    {auth.currentUser?.uid === post.userId && <Button onClick={()=>deleteBlogItem()} color='error'>Delete</Button>}
                    </Stack>
                    
                  {auth.currentUser !== null && <Stack direction={"row"} justifyContent={"space-between"}>
                    <ButtonGroup fullWidth sx={{mt:2}}>

                    <Button color='success'   onClick={()=>helpful(post.id, true)}>Helpful ({likeCount}) </Button>
                    <Button color='success'    onClick={()=>helpful(post.id, false)}>Not Helpful ({dislikeCount})</Button>
                     </ButtonGroup>
                  </Stack>}
 
                </Box>
              </Card>
            </Grid>

  );
}
