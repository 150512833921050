import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import useGetDirectory from '../hooks/useGetDirectory';
import { ProductSort, ProductList, ProductFilterSidebar } from '../sections/@dashboard/products';
import vendorCategories from '../_mock/categories';
import services from '../_mock/services';
import Explore from '../sections/@dashboard/products/Explore';


// ----------------------------------------------------------------------

export default function ProductsPage() {  
  const directories = useGetDirectory()?.filter((d)=>d.status === "APPROVED" && d.businessType !== "SERVICES") 
  const services_ = useGetDirectory()?.filter((d)=>d.businessType === "SERVICES" && d.status === "APPROVED") 
  const [value, setValue] = useState(0);
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    
  return (
    directories && <>
      <Helmet>
        <title> Directory </title>
      </Helmet>

      <Container>
  
        <Tabs value={value}  variant="scrollable" 
         onChange={handleChange} sx={{mb:4}}
         textColor='inherit'
         TabIndicatorProps={{
    style: {
      backgroundColor: "green",
      borderWidth:3
    }
  }}
 
         >
  <Tab label="Hotspots & Places" />
  <Tab label="Explore" />
  <Tab label="Services" />
</Tabs>
        

        {value === 0 && directories && <ProductList products={directories} vendorCategories={vendorCategories} />}
       {/* {value === 1 &&  directories && <DirectoryMap list={directories} />} */}
       {value === 1 && directories && <Explore list={directories}  /> }
        {value === 2 && directories && <ProductList products={services_} vendorCategories={services} />}
      </Container>
    </>
  );
}
