import { addDoc, collection, doc, getDoc, increment, setDoc, updateDoc } from 'firebase/firestore';
import React,{useEffect, useState} from 'react'
import { auth, db } from '../firebase';

function UsePagetracker(pageId, userId) {
 
    useEffect(() => {
        // Check if the user has already visited this page
        const hasVisited = localStorage.getItem(`visited_${pageId}`);
 console.log(hasVisited)
        if (hasVisited !== "true") {
            const checkDocumentExists = async () => {
                const pageRef = doc(db, 'pageStatistics', pageId);
                const pageDoc = await getDoc(pageRef);
          
                if (pageDoc.exists()) {
                    console.log("exists")
                  // Document exists, update it
                  await updateDoc(pageRef, {
                    totalViews: increment(1),
                 
                  });
                } else {
                    console.log("doesnt exists")
                  // Document doesn't exist, create it
                  await setDoc(pageRef, {
                    totalViews: 1,
                    userId
                     // Initialize to 1 for the first view
                  }).then(()=>{
                    console.log("saved")
                  });
                }
              }; 
              // Call the function to check and update/create the document
              checkDocumentExists(); 
          // Mark the page as visited to prevent multiple increments
          localStorage.setItem(`visited_${pageId}`, 'true');
        }
        else{
            console.log("page visitsd")
           
        }
      }, [pageId]);
      
      return null;
 
}

export default UsePagetracker