const services =[
  {"category": "Accountancy"},
  {"category": "Agronomist Consultants"},
  {"category": "Architecture Services"},
  {"category": "Auto Detailing"},
  {"category": "Business Coaching"},
  {"category": "Cable TV Installers"},
  {"category": "Car Painting & Body Works"},
  {"category": "Car Parking"},
  {"category": "Car Repair"},
  {"category": "Carpentry"},
  {"category": "Cleaning Services"},
  {"category": "Commission of Oaths"},
  {"category": "Consultancy"},
  {"category": "Counseling"},
  {"category": "Courier Services"},
  {"category": "Cybersecurity"},
  {"category": "Debt Collectors"},
  {"category": "Delivery Services"},
  {"category": "Drone Services"},
  {"category": "Elderly Care Services"},
  {"category": "Educational Tutoring"},
  {"category": "Electricians"},
  {"category": "Event Planner"},
  {"category": "Event Security"},
  {"category": "Escorts"},
  {"category": "Errand Running"},
  {"category": "Fitness Trainer"},
  {"category": "Funeral Services"},
  {"category": "Gardening"},
  {"category": "Graphic Design"},
  {"category": "Home Decor"},
  {"category": "Human Resources"},
  {"category": "Immigration Services"},
  {"category": "Insurance"},
  {"category": "Interior Design (Home/Office)"},
  {"category": "IT Support"},
  {"category": "Jewelry Repair"},
  {"category": "Legal Services"},
  {"category": "Maid Centres"},
  {"category": "Marketing"},
  {"category": "Market Research"},
  {"category": "Mobile Doctors"},
  {"category": "Music Lessons"},
  {"category": "Network Setup"},
  {"category": "Notary Services"},
  {"category": "Nutritionist"},
  {"category": "Online Tutoring"},
  {"category": "Pest Control"},
  {"category": "Photography"},
  {"category": "Physiotherapy"},
  {"category": "Plumbing"},
  {"category": "Private Chefs"},
  {"category": "Property Evaluator"},
  {"category": "Pool Maintenance"},
  {"category": "Septic Tank Services"},
  {"category": "Security Services"},
  {"category": "Shoes Repairer"},
  {"category": "Sign Language Interpreter"},
  {"category": "Software Development"},
  {"category": "Social Media Managers"},
  {"category": "Tattoo Removal"},
  {"category": "Therapy & Counseling"},
  {"category": "Tree Trimming"},
  {"category": "Towing"},
  {"category": "Translation Services"},
  {"category": "Veterinary Services"},
  {"category": "Videography"},
  {"category": "Web Development"},
  {"category": "Churches"},
  {"category": "Company Registration"},
  {"category": "Rehabilitation Centres"},
]




  
  export default services.sort((a, b) => a.category.localeCompare(b.category))