import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, TextField, MenuItem, Grid, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import * as Yup from 'yup'; 
import axios from 'axios';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import ShopProductCard from './ProductCard';
import vendorCategories from '../../../_mock/categories';
import services from '../../../_mock/services';
import useGetDirectory from '../../../hooks/useGetDirectory';
import provinces from '../../../data/provinces.json'
import towns from '../../../data/towns.json'
import townships from '../../../data/townships.json'
// ----------------------------------------------------------------------

export default function Explore() {

  const [provinces_, setprovinces] = useState([])
  const [towns_, settowns] = useState([])
  const [townships_, settownships] = useState([])  
  const [results, setresults] = useState([])

  const business = useGetDirectory()?.filter((d)=>d.status === "APPROVED") ;
  const [province, setprovince] = useState(null)
  const [town, settown] = useState(null)
  const [township, settownship] = useState(null)
  const [category, setcategory] = useState([])
  const [typeOfBusiness, settypeOfBusiness] = useState(null)
  const [filtered, setfiltered] = useState([])
  // const result = provinces.filter((item)=> item.prov_name === province.code)
  useEffect(() => {
    getProvinces()
  }, [])
  
  const getProvinces = ()=>{ 
    setprovinces(provinces) 
    console.log(provinces)
  }  
    const [loading, setloading] = useState(false)
 
  useEffect(() => {
   if(province) {
     getTowns()
   }
  }, [province])
  
  const getTowns = ()=>{ 
      const result = towns.filter((item)=> item.prov_code === province?.code)

    settowns(result)
    console.log(result);
  }

  useEffect(() => {
    if(town){
      getTownShips()
 }
  }, [town])
  
  const getTownShips = async()=>{ 
    const uniqueArray = townships.filter((v,i,a)=>a.findIndex(t=>(t.township_name === v.township_name))===i);
    const result = uniqueArray.filter((item)=> item.town_code === town?.code)


    settownships(result)
     
  }

const filterCategory = (cat) =>{
  setcategory(cat)
  console.log(cat)
             const filteredItems = business?.filter(item => item.category === cat);
             setfiltered(filteredItems)
}
 

async function searchLocations() {
  let filteredResults = [];
  
  if (province && !town && !township) {
    // Search by province only
    console.log(province)
    filteredResults = filtered.filter(location => location.province === province.prov_name);
  } else if (province && town && !township) {
    // Search by province and town
    console.log(town)
    filteredResults = filtered.filter(location => location.town === town.town_name);
  } else if (township) {
    // Search by province, town, and suburb
    console.log(township)
    filteredResults = filtered.filter(location => location.township === township.township_name);
  } else {
    // No search criteria provided
    console.error('Invalid search criteria');
    return [];
  }

  console.log(filteredResults);
  setresults(filteredResults);
  return filteredResults;
}

const clearForm = ()=>{
  setcategory(null)
  setprovince(null)
  settown(null)
  settownship(null)
  setfiltered(null)
  setresults([])
}


  return (
   <>
      <Helmet>
        <title> Explore</title>
      </Helmet>

      <Container> 

        <Grid container spacing={2}  >
<Grid item xs={12} md={2}>

        <TextField fullWidth label="Business Type"   select  value={typeOfBusiness}
           onChange={(event) => settypeOfBusiness(event.target.value)}
          >
           <MenuItem value={"HOTSPOTS&PLACES"}>Hotspots & Places</MenuItem> 
           <MenuItem value={"SERVICES"}>Service</MenuItem> 
          </TextField>
</Grid>
<Grid item xs={12} md={2}>

        <TextField fullWidth label="Category"   select  value={category}
           onChange={(event) => filterCategory(event.target.value)}
          >
          {typeOfBusiness && typeOfBusiness === "SERVICES" ?             
            services?.sort((a, b) => a.category.localeCompare(b.category)).map((c, index)=>(
                            <MenuItem value={c.category} key={index}><Stack>
                                <Typography>{c.category}</Typography>
                            </Stack></MenuItem>
                    )) : 
            vendorCategories?.sort((a, b) => a.category.localeCompare(b.category)).map((c, index)=>(
                            <MenuItem value={c.category} key={index}><Stack>
                                <Typography>{c.category}</Typography>
                            </Stack></MenuItem>
                    ))}
          </TextField>
</Grid>
<Grid item xs={12} md={2}>

        {provinces.length > 0 ? <TextField
            fullWidth select          
            label="Select your province"
            value={province}
            onChange={(event) => setprovince(event.target.value)}
          >
          {provinces?.sort((a, b) => a.prov_name.localeCompare(b.prov_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.prov_name}</MenuItem>
          ))}
          </TextField> : "fetching data"}
</Grid>
        <Grid item xs={12} md={2}>

        <TextField
            fullWidth select          
            label="Select your Town"
            value={town}
            onChange={(event) => settown(event.target.value)}
          >
          {towns_?.sort((a, b) => a.town_name.localeCompare(b.town_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.town_name}</MenuItem>
          ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={2}>
           <TextField
            fullWidth select          
            label="Select your Township"
            value={township}
            onChange={(event) => settownship(event.target.value)}
          >
          {townships_?.sort((a, b) => a.township_name.localeCompare(b.township_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.township_name}</MenuItem>
          ))}
          </TextField>
         </Grid>
         <Grid item xs={12} md={2}>

        <LoadingButton
        loading={loading}
          fullWidth
          size="large"
           onClick={()=>searchLocations()}
          variant="contained"
          color="warning"          
        >
          Search
        </LoadingButton>
         </Grid>
         <Grid item xs={12} md={3}>
        <Button variant="outlined" fullWidth size="large" color="warning" onClick={()=>clearForm()}>Reset</Button>
         </Grid>

        </Grid>
        {results && <Typography>{results.length} results found</Typography>}
 <br/><br/>
 <Grid container spacing={2}>
  {results?.map((r, index)=>(
                 <Grid item key={index} xs={12} md={3}>
                <ShopProductCard product={r}  />
                </Grid>
  
  ))}
 </Grid>
      </Container>
    </>
  );
}
