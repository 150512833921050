import Slider from 'react-slick';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, Grid, Card, Badge, Box, Divider, Button } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import useGetInboxById from '../hooks/useGetInboxById';
import BusinessName from '../_mock/BusinessName';
import ViewMessages from '../sections/@dashboard/Inbox/ViewMessages';
import useResponsive from '../hooks/useResponsive';
import { fToNow } from '../utils/formatTime';

// ----------------------------------------------------------------------

export default function Inbox() {
     const [selectedMessage, setselectedMessage] = useState(null)
     const isDesktop = useResponsive('up', 'lg');

    const chats = useGetInboxById(auth.currentUser?.uid, auth.currentUser?.uid)
 
      const read =  async (id) =>{
        setselectedMessage(id)
        const timeDate = Date.now()
        if(id.lastSender === auth.currentUser?.uid){
            return
        }
        await updateDoc(doc(db, "inbox", id.id), { 
            // lastUpdate : timeDate,
            status : "READ",
            
        }); 
      }

  return (
   <>
      <Helmet>
        <title> Home</title>
      </Helmet>
        
 
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Inbox
          </Typography>         
        </Stack>

<br/>
        <Grid container spacing={3}> 
 <Grid item xs={3} md={3}>
    {chats && chats?.map((b, index)=>(
        <Card key={index} sx={{p:2, mb:1, background:selectedMessage?.id === b.id ? "#919EAB" : null}} onClick={()=>read(b)}>
          { b.status === "UNREAD" && b.lastSender !== auth.currentUser?.uid ? 
          <Badge color="warning" variant="dot" badgeContent=" ">
           <BusinessName id={b.businessOwner} />
           </Badge> 
            :   
           <BusinessName id={b.businessOwner} />
       
          }
          
            {isDesktop && b.status === "UNREAD" 
            && b.lastSender !== auth.currentUser?.uid && <Typography sx={{fontSize:12}} >Unread messages</Typography>}
        {isDesktop && <Typography sx={{fontSize:12}} >{fToNow(b.lastUpdate)}</Typography>}
        </Card>
    ))}
    </Grid>
    <Grid item xs={9} md={9}> 
       {selectedMessage && <ViewMessages id={selectedMessage}  />}
    </Grid>
 </Grid>
      </Container>
    </>
  );
}
