import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, Button, Dialog, Grid, Card, Box, styled, ButtonGroup, TextField, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import Slider from 'react-slick';
 
import useGetMarketPlace from '../hooks/useGetMarketPlace';
  
import VIewItem from '../sections/@dashboard/marketplace/VIewItem';
import CompareItems from '../sections/@dashboard/marketplace/CompareItems';
 
import eventTypes from '../_mock/events';
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Marketplace() {

    const market = useGetMarketPlace()
    // const market = useGetMarketPlace()?.filter((event) => {
    //   const eventDate = new Date(event.date);
    //   const currentDate = new Date(); 
    //     return eventDate > currentDate;
       
    // });
    


    const [openView, setopenView] = useState(false)
    const [filtered, setfiltered] = useState(null)
    const [selectedCategory, setselectedCategory] = useState(null)
     const [selectedItem, setselectedItem] = useState(null)
     const [currentCategory, setCurrentCategory] = useState('All');
   
      const handleClickOpenView = (ex) => {
        setopenView(true);
        setselectedItem(ex)
      };
    
      const handleCloseView = () => {
        setopenView(false);
      
      };

      const StyledProductImg = styled('img')({
        top: 0, bottom:0, left:0, right:0,   height:300, objectFit:"cover"       
      }); 
      const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        
      };

      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ]
      };

      function filterItemsByCategory(selectedCategory) {
        setselectedCategory(selectedCategory)
             const filteredItems = market?.filter(item => item.category === selectedCategory);
        setfiltered(filteredItems)
        filterEvents("All", filteredItems)
      }
 
 
      const [events, setEvents] = useState(market);

      const filterEvents = (category,filtered ) => {
        console.log(events, filtered)
 
        const filteredEvents = filtered?.filter((event) => {
          const eventDate = new Date(event.date);
          const currentDate = new Date();
          const thirtyDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 30));
          console.log(new Date())

            if (category === 'Today') {
            return (
              eventDate.getDate() === new Date().getDate() &&
              eventDate.getMonth() === new Date().getMonth() &&
              eventDate.getFullYear() === new Date().getFullYear()
            );
          } if (category === 'Future') {
            return eventDate > new Date();
          }
          if (category === 'Past') {
            return eventDate < new Date() && eventDate >= thirtyDaysAgo;
          }
          if (category === 'All') {
            return  filtered
          }
          return false;
        });
  


      //   const filteredEvents = filtered?.filter((event) => {
      //     const eventDate = new Date(event.date);
      //     const currentDate = new Date();
      //     const thirtyDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 30));
      
      //     if (category === 'Today') {
      //         return (
      //             eventDate.getDate() === currentDate.getDate() &&
      //             eventDate.getMonth() === currentDate.getMonth() &&
      //             eventDate.getFullYear() === currentDate.getFullYear()
      //         );
      //     } 
      //     if (category === 'Future') {
      //         return eventDate > currentDate;
      //     }
      //     if (category === 'Past') {
      //         return eventDate < currentDate && eventDate >= thirtyDaysAgo;
      //     }
      //     if (category === 'All') {
      //         return  filtered
      //     }
      //     return false;
      // });
      
    
          setEvents(filteredEvents);
    
        setCurrentCategory(category);
      };
  const isDesktop = useResponsive('up', 'lg');
  return (
   <>
      <Helmet>
        <title> Events</title>
      </Helmet>

    
      
      <Dialog
        open={openView}
        onClose={handleCloseView}
      ><VIewItem handleClose={handleCloseView} data={selectedItem} />
      </Dialog>


      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Events
          </Typography>  
        </Stack> 
    
        <Stack sx={{mb:4,}}  direction="row" flexWrap="wrap">
           {isDesktop  ? eventTypes.sort((a, b) => a.localeCompare(b)).map((bc, index)=>(
            <div key={index}>
                   <Button variant={selectedCategory === bc ? "contained" : "outlined"} sx={{m:1}}  color='success' onClick={()=>filterItemsByCategory(bc)}>{bc}</Button>
             
              </div>
          )) :
          <TextField fullWidth label="Category"   select  value={selectedCategory}
           onChange={(event) => filterItemsByCategory(event.target.value)}
          >
          {eventTypes?.sort((a, b) => a.localeCompare(b)).map((c, index)=>(
                            <MenuItem value={c} key={index}><Stack>
                                <Typography>{c}</Typography>
                            </Stack></MenuItem>
                    ))}
          </TextField>
          }
      
 
</Stack> 
 
<Stack sx={{my:3}}>
<Typography variant='h4'>{selectedCategory && selectedCategory}</Typography>

{filtered ? <Stack direction="row" flexDirection={"wrap"} spacing={3}>
<Button   
          onClick={()=>filterEvents("All", filtered)} variant={currentCategory === "All" ? "contained" : "outlined"} sx={{m:1}}  color='success'>All</Button>
          <Button  
          onClick={()=>filterEvents("Today", filtered)} variant={currentCategory === "Today" ? "contained" : "outlined"} sx={{m:1}}  color='success'>Today</Button>
          <Button  
           onClick={()=>filterEvents("Future", filtered)} variant={currentCategory === "Future" ? "contained" : "outlined"} sx={{m:1}}  color='success' >Future</Button>
          <Button  
           onClick={()=>filterEvents("Past", filtered)} variant={currentCategory === "Past" ? "contained" : "outlined"} sx={{m:1}}  color='success'>Past</Button>
</Stack> : 
<Stack direction="row" flexDirection={"wrap"} spacing={3}>
          <Button   
          onClick={()=>filterEvents("All", market)} variant={currentCategory === "All" ? "contained" : "outlined"} sx={{m:1}}  color='success'>All</Button>
          <Button  
          onClick={()=>filterEvents("Today", market)} variant={currentCategory === "Today" ? "contained" : "outlined"} sx={{m:1}}  color='success'>Today</Button>
          <Button  
           onClick={()=>filterEvents("Future", market)} variant={currentCategory === "Future" ? "contained" : "outlined"} sx={{m:1}}  color='success' >Future</Button>
          <Button  
           onClick={()=>filterEvents("Past", market)} variant={currentCategory === "Past" ? "contained" : "outlined"} sx={{m:1}}  color='success'>Past</Button>
</Stack>}
</Stack>
 <Grid container spacing={3}>
    {events ? events.length === 0 ? 
      <Stack sx={{my:3, p:3}}><Typography variant='h6'>No results found.</Typography></Stack> : events?.map((m, index)=>(
      <Grid item xs={12} md={4} key={index}>
            <Card >
                <Box>
                <Slider {...settings2}>
                    {m.images?.map((i, index)=>(
                      <StyledProductImg key={index} alt={m.name} src={i} />
                    ))}
                </Slider>
                <Stack spacing={2} sx={{p:2}}>
                         <Typography variant="h5">{m.eventTitle}<br/>
                        <Typography component="span"> {m.eventDescription}</Typography></Typography>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Typography>{m.time}</Typography>
                            <Typography>{m.date}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} sx={{my:2, mb:2}} justifyContent={"space-between"} >
                         <Button variant='contained' color='warning' onClick={()=>handleClickOpenView(m)}>View</Button>
                    </Stack>
                </Box>
            </Card>
        </Grid>
    )) :  market?.map((m, index)=>(
        <Grid item xs={12} md={4} key={index}>
            <Card >
                <Box>
                <Slider {...settings2}>
                    {m.images?.map((i, index)=>(
                      <StyledProductImg key={index} alt={m.name} src={i} />
                    ))}
                </Slider>
                <Stack spacing={2} sx={{p:2}}>
                         <Typography variant="h5">{m.eventTitle}<br/>
                        <Typography component="span"> {m.eventDescription}</Typography></Typography>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Typography>{m.time}</Typography>
                            <Typography>{m.date}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} sx={{mx:2, mb:2}}  justifyContent={"space-between"} >
                          <Button variant='contained' color='warning' onClick={()=>handleClickOpenView(m)}>View</Button>
                    </Stack>
                </Box>
            </Card>
        </Grid>
    ))}
 </Grid>
      </Container>
    </>
  );
}
