import { useEffect, useState } from 'react';
/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography,  Box, Card, Link, Divider, Button, Grid, ButtonGroup, Tabs, Tab, Dialog } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { styled } from '@mui/material/styles'; 
import Label from '../../../components/label/Label';
import { auth, db } from '../../../firebase'; 
import useGetAlbumById from '../../../hooks/useGetAlbumById';
import SharePost from './SharePost';
import ViewPricing from './ViewPricing';
import UsePagetracker from '../../../hooks/UsePagetracker';
import SendMessage from '../Inbox/SendMessage';
import Iconify from '../../../components/iconify/Iconify';
import useGetBusiness from '../../../hooks/useGetBusiness';


function VendorPage() {
  const location_ = useLocation()
  const searchParams = new URLSearchParams(location_.search);
  const id_ = searchParams.get('id');
 
  const business = useGetBusiness(id_)
  
  const navigate = useNavigate()
  
  
    UsePagetracker(business?.id, business?.userId)

  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0); 
  const StyledProductImg = styled('img')({       
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  });

  const StyledProductCover = styled('img')({
    top: 0,
    width: '100%',
    height: "100%",
    objectFit: 'cover', 
    position:"absolute"
  });

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect( () => {
    if(auth.currentUser?.uid === undefined || auth.currentUser?.uid === null){
      return
    }
    if( business === null || business === undefined){
      return
    }
    async function getData()
    {
      onSnapshot( doc(db, "app_users", auth.currentUser?.uid, "likes", business?.id), (doc) => {
        if (doc.exists()) {
          setIsFavorite(true)
         
        } else {
          // docSnap.data() will be undefined in this case
          setIsFavorite(false)
         
        }
          });    
    }

getData()
  }, [business, auth.currentUser?.uid])
  
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const addToFavorite =async () =>{
    if(isFavorite)
    {
      await deleteDoc(doc(db, "app_users", auth.currentUser?.uid, "likes", business?.id)) 
    }
    else{
    await setDoc(doc(db, `app_users`, auth.currentUser?.uid, "likes", business?.id),  {item :  business?.id});

    }
  }
      
  useEffect(() => {
    // Query Firestore for the current user's like/dislike status for the item
    if( business === null || business === undefined){
      return
    }
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userQuery = query(collection(db, 'businessRecommendation'), where('postId', '==',  business?.id), where('userId', '==', userId));
      const userQueryUnsubscribe = onSnapshot(userQuery, (userSnapshot) => {
        if (!userSnapshot.empty) {
          setIsLiked(userSnapshot.docs[0].data().is_liked);
        }
      });
    }
  
   
    // Query Firestore for the total number of likes and dislikes for the item
    const itemQuery = query(collection(db, 'businessRecommendation'), where('postId', '==',  business?.id));
    const itemQueryUnsubscribe = onSnapshot(itemQuery, (itemSnapshot) => {
      let likeCount = 0;
       itemSnapshot.forEach((doc) => {
        if (doc.data().is_liked) {
          likeCount += 1;
        }  
      });
      setLikeCount(likeCount); 
    });
  
    // Clean up listeners when the component unmounts
    // eslint-disable-next-line consistent-return
    return () => {
      if (itemQueryUnsubscribe) itemQueryUnsubscribe(); 
    };
  }, [ business?.id]);


  const helpful = async ()=>{
    const userId = auth.currentUser?.uid;

    // Create a Firestore query to check if the user has already liked/disliked the item
    const q = query(collection(db, 'businessRecommendation'), where('postId', '==',  business?.id), where('userId', '==', userId));
  
    try {
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        // User has already liked/disliked the item, update the existing document
        const doc = querySnapshot.docs[0];
        const isLiked = !doc.data().is_liked;
        await updateDoc(doc.ref, { is_liked: true });
        setIsLiked(isLiked);
      } else {
        // User has not yet liked/disliked the item, create a new document
       await addDoc(collection(db, 'businessRecommendation'), {
          postId:  business?.id,
          userId,
          is_liked: true,
          count: 1,
        });
        setIsLiked(true);
      }
    } catch (error) {
      console.error('Error handling like click:', error);
    }
  }
  const [openView, setopenView] = useState(false)
  const handleClickOpenView = () => {
    setopenView(true);
   
  };

  const handleCloseView = () => {
    setopenView(false);
 
  };
    const albums = useGetAlbumById( business?.id)
    const [selectedGallery, setselectedGallery] = useState(null)
    const viewMore = (id) =>{
      setselectedGallery(id)
    }

    
  return (
    <>
      <Helmet>
        <title>View Profile</title>
      </Helmet>

      <Dialog
        open={openView}
        onClose={handleCloseView}
      ><SendMessage handleClose={handleCloseView} data={ business?.userId} busId={ business?.id} />
      </Dialog>

      <Container>

      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          <Typography component={"span"}>
        Profile
          </Typography><br/>
          { business?.name}
          </Typography>    
          {isFavorite ? <Button variant='text' color='warning' onClick={()=>addToFavorite()}><Iconify icon="eva:heart-fill" /></Button>     
          :
          <Button  variant='text' color='success'  onClick={()=>addToFavorite()}><Iconify icon="eva:heart-outline" /></Button>     
          }
          
        </Stack>
        <Card>
      <Box sx={{ pt: '45%', position: 'relative' }}>
       
          <Label
            variant="filled"
            color={'warning'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            { business?.category}
          </Label>
    
        <StyledProductCover alt={ business?.name} src={business?.cover_image || "../assets/bck_long.png"} />
      </Box>

      <Stack spacing={2} justifyContent={"space-between"}  sx={{ p: 3 }}>
      <Divider sx={{my:3}}>Contact/Address</Divider>
          <a href={`tel:0${business?.phone}`}><Typography variant="subtitle2"  noWrap> <Iconify icon="ic:round-phone"/>
          {String(business?.phone).charAt(0) === '0' ?  business?.phone :  `0${business?.phone}`} 
          </Typography> </a>
         
            <Typography variant="subtitle2" noWrap>
            { business?.address}
          </Typography>

          {business?.town !== null || business?.township !== null || business?.province !== null ? (
  <Typography variant="subtitle2">
    {`${business?.town}, ${business?.township}, ${business?.province}`}
  </Typography>
) : null}

          <Typography  variant="subtitle2" >Opens {business?.startTime} - Closes {business?.closeTime}</Typography>
            <Divider sx={{my:2}} >Our Socials</Divider>
            { business?.facebook && <Typography variant="subtitle2" noWrap>
            <Iconify icon="logos:facebook" /> { business?.facebook}
          </Typography>}
          { business?.instagram && <Typography variant="subtitle2" noWrap>
            <Iconify icon="skill-icons:instagram" /> { business?.instagram}
          </Typography>}
            {business?.x && <Typography variant="subtitle2" noWrap>
            <Iconify icon="simple-icons:x" /> { business?.x}
          </Typography>}

            {business?.whatsapp && <Typography variant="subtitle2" noWrap>
            <Iconify icon="ph:whatsapp-logo" color="green" /> {String(business?.whatsapp).charAt(0) === '0' ?  business?.whatsapp :  `0${business?.whatsapp}`}
          </Typography>}
            
            {/* new */}
            {business?.linkedIn && <Typography variant="subtitle2" noWrap>
            <Iconify icon="simple-icons:linkedin" color="blue" /> {String(business?.linkedIn).charAt(0) === '0' ?  business?.linkedIn :  `${business?.linkedIn}`}
          </Typography>}
            {business?.tiktok && <Typography variant="subtitle2" noWrap>
            <Iconify icon="simple-icons:tiktok" color="black" /> {String(business?.tiktok).charAt(0) === '0' ?  business?.tiktok :  `${business?.tiktok}`}
          </Typography>}
            {business?.youtube && <Typography variant="subtitle2" alignItems="center" justifyContent="center" noWrap>
            <Iconify icon="simple-icons:youtube" color="red" /> {String(business?.youtube).charAt(0) === '0' ?  business?.youtube :  `${business?.youtube}`}
          </Typography>}
            

            {/* end of new */}
            { business?.website && <Typography variant="subtitle2" noWrap>
            <Iconify icon="mdi:web" color="blue" /> { business?.website}
          </Typography>}
            <Divider sx={{my:3}}>Services</Divider>
            <Stack direction="row" justifyContent={"center"} flexWrap={"wrap"} spacing={3}>
            { business?.tags?.map((a, i)=>(
              <Button variant="text" key={i} onClick={()=>navigate("/dashboard/search", {state: a})}  color={"warning"}>
              {a}
              </Button>
            ))}
            </Stack>
        
        
            <Button variant='contained' color='success' onClick={()=>handleClickOpenView()}>Send Message</Button>

          <Divider sx={{my:3}}>About</Divider>
          <Typography variant="body1"  >
            { business?.description}
          </Typography>

          
          {/* {location && 
          <Stack>
          <Divider sx={{my:3}}>Map</Divider>
          <Map
      mapboxAccessToken="pk.eyJ1IjoiY2hpc29tbyIsImEiOiJjbHBncmx4YWgwMTVnMnFyd2Q0amNoM2YwIn0.SLmKpgy7_N8d0j7joRClAA"
      initialViewState={{
        longitude: location?.long,
        latitude: location?.lat,
        zoom: 14
      }}
       style={{width: "100%", height:400}}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    >
        {location ? <Marker longitude={location?.long} anchor='bottom' latitude={location?.lat} color="red" /> : <Typography>Map Data not provided</Typography>}
    </Map>
    </Stack>
    } */}

          <Button color='success' disabled={isLiked}  onClick={()=>helpful()}>I Recommend  ({likeCount}) </Button>
<Divider sx={{my:3}} />
          { business?.maps !== null && 
          <Button color="success" href={ business?.maps} target='_blank' variant="contained">Get Directions on Google</Button>}
       </Stack>
   
 
       </Card>
   
        </Grid>
        <Grid item xs={12} md={7}>
        <Tabs value={value} onChange={handleChange} sx={{mb:4}}>
        <Tab label="Gallery" />
        <Tab label="Pricing" />
        <Tab label="Feedback" />
        </Tabs>

         {value === 2 && <SharePost id={ business?.id} userId={ business?.userId} />}   
         {value === 0 && <Grid container spacing={3}>
    
    {albums && albums?.map((g, index)=>(
        <Grid item xs={12} md={12} lg={12} key={index}>
        <Stack direction={"row"} spacing={3} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="h4">{g.name}
            <br/><Typography component={"span"}>{g.description}</Typography></Typography>
          
        </Stack>
    
      
        <Grid container spacing={1}>
            {g.images?.slice(0, selectedGallery === g.id ? g.images.length : 3).map((h, index)=>(
        <Grid item xs={12} md={3} key={index}>
                <StyledProductImg alt={h} key={index} src={h} />
            </Grid>
            ))}
            {g.images?.length > 3 && <Grid item xs={12} md={3}>
              {selectedGallery === g.id ? <Button onClick={()=>setselectedGallery(null)}>Show Less</Button>
              : <Button onClick={()=>viewMore(g.id)}>View More</Button>}
            </Grid>}
        </Grid>
        </Grid>
    ))  }
    </Grid>}
    {value === 1 && <ViewPricing id={ business?.id} />}
        </Grid>
      </Grid>
     

      
   
   
    
       

      </Container>
    </>
  )
}

export default VendorPage