import  {useState, useEffect} from 'react'
import { collection,  onSnapshot, orderBy, query } from 'firebase/firestore';
 
import {  auth, db,} from '../firebase';

function useGetMessagesById(id) { 
    
        const [user, setuser] = useState(null)
        useEffect(() => {
          if(id === undefined || id === null){
            return
          }
            const q = query(collection(db, "inbox", id, "messages"), orderBy("createdAt", "desc"))
            onSnapshot(q, (querySnapshot) => {        
             const data =[]
             querySnapshot.forEach((doc) => {
                 const asd = {
                   ...doc.data(),
                   id: doc.id
                 }
                data.push(asd)      
               })
               
               setuser(data)
              });
        }, [id])
      return user
    }

export default useGetMessagesById