import { Timestamp, arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import React, {useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { Button, CircularProgress, Container, Grid, Stack, TextField, Typography, styled } from '@mui/material';
import { db } from '../../../firebase';
import useGetAlbum from '../../../hooks/useGetAlbum';

function UploadImages() {

    const navigate = useNavigate()
    const location = useLocation() 
    const [progress, setprogress] = useState(0)
const [loading, setloading] = useState(false)
    
const docId = useGetAlbum(location.state.albumId, location.state.id)
  const {imagesPerAlbum} = location.state
   console.log(docId)

    const [images, setImages] = useState([]);
    const storage = getStorage()
 
      const handleMultipleImageChange = (event) => {
        if (event.target.files && event.target.files.length > 0) { 
          const selectedImages = Array.from(event.target.files);       
          if (selectedImages.length > imagesPerAlbum) { 
            const firstTenImages = selectedImages.slice(0, (imagesPerAlbum - selectedImages.length));
            uploadImages(firstTenImages) 
          } else {
            uploadImages(selectedImages) 
          }
        }
      };

      const uploadImages = async (images) => {
        setloading(true)
        const storageRef = ref(storage, `business/`); // Define the storage reference
      
        // eslint-disable-next-line no-async-promise-executor
        const uploadPromises = images.map((image) => new Promise(async (resolve) => {
            const fileRef = ref(storageRef, `${image.name}_${Timestamp.now().seconds}`);
            const uploadTask = uploadBytesResumable(fileRef, image);
      
            // Create a progress bar element for each file
            const progressBar = document.createElement("div");
            progressBar.classList.add("progress-bar");
            progressBar.style.width = "0%"; // Initialize progress to 0%
            progressBar.textContent = "0%";
      
            // Append the progress bar to your UI (e.g., a div with id "progress-bars")
            const progressBarsContainer = document.getElementById("progress-bars");
            progressBarsContainer.appendChild(progressBar);
      
            // Listen to state_changed event to track progress
            uploadTask.on('state_changed',
              (snapshot) => {
                // Calculate progress (percentage uploaded) here for each image
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const imp = images.length * progress
                setprogress(imp)
                progressBar.style.width = `${progress}%`;
                progressBar.textContent = `${Math.round(progress)}%`;
              },
              (error) => {
                console.error(`Error uploading ${image.name}: ${error.message}`);
                setloading(false)
                resolve(null);
              },
              () => {
                // Upload completed successfully
                getDownloadURL(fileRef).then(async (downloadURL) => {
                  console.log(`File ${image.name} available at ${downloadURL}`);
                  
                  // Update the images array in Firebase for each uploaded image
                  const userDocRef = doc(db, 'business',location.state.albumId, "albums", location.state.id);
                  await updateDoc(userDocRef, {
                    images: arrayUnion(downloadURL),
                  });
      
                  // Remove the progress bar when the upload is complete
                  progressBarsContainer.removeChild(progressBar);
      
                  resolve(downloadURL);
                });
              }
            );
          }));
      
        try {
          const uploadedImageUrls = await Promise.all(uploadPromises);
          console.log('All images uploaded:', uploadedImageUrls);
          setImages([]); // Clear the uploaded images
          setprogress(0)
          setloading(false)
           } catch (error) {
          console.error('Error uploading images:', error);
          setloading(false)
        }
      };
       
      const StyledProductImg = styled('img')({
         width: '100%',
        height: '100%',
        objectFit: 'cover',
  
      });

      async function  deleteAlbum(){
        await deleteDoc(doc(db,  'business',location.state.albumId, "albums", location.state.id)).then(()=>{
            navigate(-1)
        })
}


async function  deleteFile(file){
  const userDocRef = doc(db, 'business',location.state.albumId, "albums", location.state.id);
  await updateDoc(userDocRef, {
    images: arrayRemove(file),
  }).then(()=>{
    const desertRef = ref(storage, file);              
    deleteObject(desertRef).then(() => {
      alert("File deleted successfully.")                
    }).catch((error) => {
      alert("Error, failed to delete the file")
    });
  });
   }
   const [albumName, setalbumName] = useState()
   const [albumDescription, setalbumDescription] = useState()
   useEffect(() => {
    setalbumName(docId?.name)
    setalbumDescription(docId?.description)
   }, [docId])
   

   const [showEditSection, setshowEditSection] = useState(false)

   const editAlbum = async () =>{
    setloading(true)
    await updateDoc(doc(db,  'business',location.state.albumId, "albums", location.state.id), { 
      name : albumName,
      description : albumDescription     
  }).then(()=>{
    alert("Album updated")
    setshowEditSection(!showEditSection)
    setloading(false)
  }).catch(()=>alert("Error updating. Try again later.")); 
   

   }
 
  return (
    <>
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
         <Typography component={"span"}>Album Name </Typography><br/>
          {docId?.name}
        </Typography>  
    <Stack direction="row" spacing={3} justifyContent={"space-between"}>
      <Button onClick={()=>setshowEditSection(!showEditSection)} variant="contained" color='success'>Edit Album</Button>
      <Button onClick={()=>deleteAlbum()} variant="contained" color='error'>Delete Album</Button>

    </Stack>
 
      </Stack>
      {showEditSection && <Stack>
        <Typography>Update Album details</Typography><br/>
 <Stack  direction="row" sx={{mb:5}} spacing={3} justifyContent={"space-between"}>
 {albumName && <TextField fullWidth type="text" label="Album Name" value={albumName} onChange={(event) => setalbumName(event.target.value)} />}
 {albumDescription && <TextField fullWidth type="text" label="Album Description" value={albumDescription} onChange={(event) => setalbumDescription(event.target.value)} />}
 <LoadingButton loading={loading} onClick={()=>editAlbum()} fullWidth variant="contained" color='success'>Update Album</LoadingButton>
 </Stack>
      </Stack>}
       
      <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
      <Stack  sx={{height:"100%"}}  justifyContent={"center"} alignItems={"center"}>

{(docId?.images?.length || 0 )< imagesPerAlbum && <Typography variant="h5">Add {imagesPerAlbum - (docId?.images?.length || 0)} images more <br/><br/>

  {!loading && <input type="file" onChange={handleMultipleImageChange}  multiple accept="image/*" />  }
    </Typography>
}</Stack>
      <Stack direction="row">
    <div id="progress-bars" />
        {progress > 0 && <CircularProgress fullWidth variant="determinate" value={progress} sx={{ my: 2 }}/>       }
      </Stack>
      </Grid>
        {docId?.images?.map((h, index)=>(
    <Grid item xs={12} md={3} key={index}>
    <Button color="error" onClick={()=>deleteFile(h)} style={{position:"absolute"}} variant="contained">Delete</Button>
            <StyledProductImg alt={h} key={index} src={h} />
        </Grid>
        ))}
    </Grid>

    </Container>
  </>
  )
}

export default UploadImages