import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Container, Typography, Button, Dialog, Grid, Card, Box, styled, Divider, Tabs, Tab } from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import { signOut } from 'firebase/auth';
import Slider from 'react-slick';
import useGetBusinessById from '../hooks/useGetBusinessById';
import { auth } from '../firebase';
import Label from '../components/label/Label';
import SellItem from '../sections/@dashboard/marketplace/SellItem';
import useGetMarketplaceById from '../hooks/useGetMarketplaceById';
import { fNumber } from '../utils/formatNumber';
import EditItem from '../sections/@dashboard/marketplace/EditItem';
import useGetFavorites from '../hooks/useGetFavorites';
import Business from '../sections/@dashboard/profile/Business';
import MyProfile from '../sections/profile/MyProfile';
import Analytics from '../sections/@dashboard/products/Analytics';
import useGetBenefits from '../hooks/useGetBenefits';
import useGetExpired from '../hooks/useGetExpired';
import useGetUser from '../hooks/useGetUser';
import VIewItem from '../sections/@dashboard/marketplace/VIewItem';
// components
 

function ProfilePage() {
  
  const navigate = useNavigate()
  const user = useGetUser(auth.currentUser?.uid)
  const [open, setOpen] = useState(false);
  const [openEdit, setopenEdit] = useState(false)
  const [selectedItem, setselectedItem] = useState(null)

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    
    const handleClickOpenEdit = (ex) => {
      setselectedItem(ex)
      setopenEdit(true);
    };
  
    const handleCloseEdit = () => {
      setopenEdit(false);
    };

    const businesses = useGetBusinessById(auth.currentUser?.uid)
    const businesses_ = useGetBusinessById(auth.currentUser?.uid)?.filter((d)=>d.businessType !== "SERVICES") 
    const services = useGetBusinessById(auth.currentUser?.uid)?.filter((d)=>d.businessType === "SERVICES") 
    const market = useGetMarketplaceById(auth.currentUser?.uid)
    const favorites = useGetFavorites(auth.currentUser?.uid)
 
    const StyledProductImg = styled('img')({
      top: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      position: 'absolute',
    });
    const StyledProductMarket = styled('img')({
      top: 0, bottom:0, left:0, right:0,  height:300, objectFit:"cover" 
      
    });
    const settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      
    };

    const [value, setValue] = useState(0);

    const [openView, setopenView] = useState(false)
    const benefits = useGetBenefits()
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleCloseView = () => {
      setopenView(false);
      
    };

    const handleClickOpenView = (ex) => {
      setopenView(true);
      setselectedItem(ex)
    };
    const expired = useGetExpired()

    
    
  return (
    <>
      <Helmet>
        <title> Profile</title>
      </Helmet>
      <Dialog
        open={open}
        onClose={()=>handleClose()}
      ><SellItem handleClose={()=>handleClose()} />
      </Dialog>
      
      <Dialog
        open={openEdit}
        onClose={()=>handleCloseEdit()}
      ><EditItem handleClose={()=>handleCloseEdit()} data={selectedItem} />
      </Dialog>

      <Dialog
        open={openView}
        onClose={handleCloseView}
      ><VIewItem handleClose={handleCloseView} data={selectedItem} />
      </Dialog>


      <Container>
       

         <Tabs value={value}  variant="scrollable" onChange={handleChange} sx={{mb:4}}>
        <Tab label="Businesses" />
        <Tab label="Services" />
        <Tab label="Events" />
        <Tab label="Favorites" />
        <Tab label="Profile" />
       
        </Tabs>

{value === 0 && <div>
         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
         <Typography variant="h6" gutterBottom>
            My Businesses
          </Typography> 
            {benefits && businesses?.length < benefits?.features?.businesses ? 
              expired && <Button onClick={()=>navigate("/dashboard/addBusiness")} variant='contained' color="warning">Add Business ({benefits?.features?.businesses - businesses?.length} left)</Button> : "You have reached your limit"}
          
         </Stack> 
      <Grid container spacing={3}>
     
      {businesses_ && businesses_.map((b, index)=>(
        <Grid item xs={12} md={3} key={index} onClick={()=>navigate("/dashboard/managePage", {state : b})} >
        <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
       
          <Label
            variant="filled"
            color={'warning'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {b.category}
          </Label>
      <StyledProductImg alt={b.name} src={b.cover_image ? b.cover_image : "../assets/bck.png"} />
      </Box>
      <Stack spacing={2} justifyContent={"space-between"} direction="row" sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            {b.name}
          </Typography>
      
              <Typography
              variant="overline"             
            >
        {b.status}
            </Typography>           
         
       </Stack>
    </Card>
        </Grid>
      ))}
      </Grid>
</div>}

{value === 1 && <div>
         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
         <Typography variant="h6" gutterBottom>
            My Services
          </Typography> 
            {benefits && businesses?.length < benefits?.features?.businesses ? 
              expired && <Button onClick={()=>navigate("/dashboard/addBusiness")} variant='contained' color="warning">Add Business ({benefits?.features?.businesses - businesses?.length} left)</Button> : "You have reached your limit"}
          
         </Stack> 
      <Grid container spacing={3}>
     
      {services && services.map((b, index)=>(
        <Grid item xs={12} md={3} key={index} onClick={()=>navigate("/dashboard/managePage", {state : b})} >
        <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
       
          <Label
            variant="filled"
            color={'warning'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {b.category}
          </Label>
      <StyledProductImg alt={b.name} src={b.cover_image ? b.cover_image : "../assets/bck.png"} />
      </Box>
      <Stack spacing={2} justifyContent={"space-between"} direction="row" sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            {b.name}
          </Typography>
      
              <Typography
              variant="overline"             
            >
        {b.status}
            </Typography>           
         
       </Stack>
    </Card>
        </Grid>
      ))}
      </Grid>
</div>}

{value === 2 && <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={4}>
         <Typography variant="h6" gutterBottom>
            My Events
          </Typography> 
          {benefits && market?.length < benefits?.features?.events ? 
            expired &&  user?.account_status === "APPROVED" && <Button onClick={()=>handleClickOpen()} variant='contained' 
            color="success">Add Event ({`${benefits?.features?.events - market?.length}`} left)</Button> : "You have reached your limit"} 
          </Stack>

          <Grid container spacing={3}>
            {market?.map((m, index)=>(
              <Grid item xs={12} md={4} key={index}>
            <Card>
                <Box>
                <Slider {...settings2}>
                    {m.images?.map((i, index)=>(
                      <StyledProductMarket key={index} alt={m.name} src={i} />
                    ))}
                </Slider>
                    <Stack spacing={2} sx={{p:2}}>
                         <Typography variant="h5">{m.eventTitle}<br/>
                        <Typography component="span"> {m.eventDescription}</Typography></Typography>
                        <Stack direction="row" justifyContent={"space-between"}>
                            <Typography>{m.time}</Typography>
                            <Typography>{m.date}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Button fullWidth color='success'  onClick={()=>handleClickOpenEdit(m)}>Edit event</Button>
                      <Button fullWidth color='warning'  onClick={()=>handleClickOpenView(m)}>View event</Button>
                    </Stack>
                </Box>
            </Card>
        </Grid>
            ))}
            </Grid>
</div>}

{value === 3 && <div>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={4}>
         <Typography variant="h6" gutterBottom>
            My Favorites
          </Typography>  
      
          </Stack>
            <Grid container spacing={3}>

          {favorites?.map((f, index)=>( 
          <Business id={f.item} key={index} /> 
          ))}
            </Grid>
            </div>}

{value === 4 && <MyProfile />} 
{value === 5 && <Analytics />}
      </Container>
    </>
  )
}

export default ProfilePage