/* eslint-disable camelcase */
import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { useNavigate } from 'react-router-dom';
import Label from '../../../components/label'; 
import { auth } from '../../../firebase';
import useGetUser from '../../../hooks/useGetUser';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, cover_image,  category,township,  address, id, account} = product;
console.log(product,"products")
//  const accountType = useGetUser(userId)
  const navigate = useNavigate()
  return (
    <Card onClick={auth.currentUser?.uid === undefined ? ()=>navigate("/login") : ()=>navigate(`/dashboard/vendorPage?id=${id}`, {state : product})}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
       
          <Label
            variant="filled"
            color={'warning'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {category}
          </Label>
          
          {account === "PRO" && <Label
            variant="filled"
             
            sx={{
              zIndex: 9,
              bottom: 16, backgroundColor:"#d4af37",
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
           PREMIUM 
           {/* {accountType?.account} */}
          </Label>}
    
        <StyledProductImg alt={name} src={cover_image ?? "../assets/bck.png"} />
      </Box>

      <Stack spacing={2} justifyContent={"space-between"} sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            {name}<br/>
            <Typography
              component={"span"}    >
        {address}
            </Typography> 
          </Typography>
      
              <Typography
              variant="overline"    >
        {township}
            </Typography>           
                      
         
       </Stack>
    </Card>
  );
}
