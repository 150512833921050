import { LoadingButton } from '@mui/lab';
import { AppBar, Toolbar, Typography, Button, Stack, TextField, Divider, CircularProgress, Card, Box, styled } from '@mui/material'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import { auth, db } from '../../../firebase';

function UpdateCoverImage({id, data}) {

    
    const storage = getStorage()
      const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);

      const handleUpload = () => {
       
        if (images.length > 0) {
          uploadImage(images[0]);
        }  
      }; 
   
        const uploadImage = async (image) => {  
          setloading(true)
          if(image){
            const _id = Date.now();
  
            const fileRef = ref(storage, `businessCoverIMage/${image.name}_${_id}`);
            const uploadTask = uploadBytesResumable(fileRef, image);
          // Listen to state_changed event to track progress
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(progress)
              setUploadProgress(progress);
            },
            (error) => {
              console.error(`Error uploading ${image.name}: ${error.message}`); 
            },
            () => {
              // Upload completed successfully
              getDownloadURL(fileRef).then(async (downloadURL) => {
                await updateDoc(doc(db, "business", id), {
                    updatedAt : Date.now(),
                  cover_image : downloadURL,                
                });
      });
       setImages([])
      setUploadProgress(0)
      setloading(false)
            }
          ); }
      }  
     
      async function  deleteFile(){
        await updateDoc(doc(db, "business", id), {
          updatedAt : Date.now(),
        cover_image : null,                
      }).then(()=>{
        alert("cover image removed")
       }) }
       const StyledProductImg = styled('img')({
   
        width: '100%',
        height: 200,
        objectFit: 'cover',
        
      });

  return (
    <div>
        <Stack mb={5}>
          <Typography variant="h6" gutterBottom>
            Upload Cover Image
          </Typography>      
          <StyledProductImg alt={data?.name} src={data?.cover_image ? data?.cover_image : "../assets/bck.png"} />
      
          <Card sx={{p:3}}>
    <Box>    
        <Stack      >
  

  {uploadProgress > 0 && <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />}
    <Stack direction={"row"} sx={{mt:2}} justifyContent={"space-between"}>
    <Dropzone onDrop={files => setImages(files)}>
                {({getRootProps, getInputProps}) => (
                  <div className="container">
                  <Card sx={{p:1, px:3, border:" thin solid #ED6C02"}} 
                      {...getRootProps({
                        className: 'dropzone',
                        onDrop: event => event.stopPropagation()
                      })}
                    >
                      <input {...getInputProps()} />
                      <p>Click here to select files</p>
                      {images.length === 1 && images[0].name}
                    </Card>
                    </div>
                )}
              </Dropzone>
  
    </Stack><br/>
        </Stack>
        </Box>
        <Stack direction="row" justifyContent={"space-between"}>
      <LoadingButton loading={loading}   variant="contained" onClick={()=>handleUpload()} color="warning">Upload Cover Image</LoadingButton>
      <Button  variant="contained" onClick={()=>deleteFile()} color="error">Delete Cover Image</Button>

        </Stack>
    </Card>
        </Stack>
    </div>
  )
}

export default UpdateCoverImage