import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Divider } from '@mui/material';
import {auth, onAuthStateChanged } from '../../../firebase';
import useGetUser from '../../../hooks/useGetUser';

// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/Scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import noauthnavConfig from './noauth';
import useGetExpired from '../../../hooks/useGetExpired';

// ----------------------------------------------------------------------

const NAV_WIDTH = 180;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const [user, setUser] = useState(null);
  const account = useGetUser(auth.currentUser?.uid)
 const navigate  = useNavigate()
const expired = useGetExpired()
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {
       
        setUser(true);
      } else {
      
        setUser(false);
      }
    });
  }, [user]);
  
  const isDesktop = useResponsive('up', 'lg');



  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]); 
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box onClick={()=>navigate("/")} sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
      <img src="/assets/logo.png" style={{ width:70}}  alt="login" />
      </Box>

      {account ?  <Box sx={{ mb: 1, mx: 1, mt:1 }}>
        <Link underline="none"  onClick={()=>navigate("/dashboard/profile")}>
          <StyledAccount>
          <Avatar sx={{ bgcolor: 'background.neutral', width : 32, height:32 }} src={account?.profile_image} />

        
             <Box sx={{ ml: 2 }} >
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {account?.fullName}
              </Typography> 
            </Box>
          </StyledAccount>
        </Link>
      </Box> : <Button sx={{m:2}} variant='contained' onClick={()=>navigate("/login")} color="warning">Sign In/Up</Button>}

      <Divider sx={{my:2}} />
      <NavSection data={user ? navConfig : noauthnavConfig} />

        {user && <Box sx={{ p:2 }} >
      {expired &&     <Button color="warning" onClick={()=>navigate("/dashboard/addBusiness")} variant="contained">
           Add Business
          </Button>}
         </Box>}


      {account?.account === "PRO" ? 
      <Box sx={{ px: 2.5, pb: 3, }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
  

          {expired ? <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
            Congratulations! Welcome to Premium Membership 
            </Typography> 
            <Button color="success" onClick={()=>navigate("/dashboard/upgradetopro")} variant="contained">
          SUBSCRIPTIONS
          </Button>
          </Box> : 
          <Box sx={{ textAlign: 'center' }}>
            {account.account_status === "PENDING" ? 
            <Typography gutterBottom variant="h6">
             Your account is under review.
            </Typography> : <Typography gutterBottom variant="h6">
            Your subscription has expired. Do not be left out! Renew to continue growing your business
            </Typography>} 
          {!expired && <Button color="success" onClick={()=>navigate("/dashboard/upgradetopro")} variant="contained">
          Click here
          </Button>}
          </Box>
          }

        </Stack>
      </Box>
       :
       <Box sx={{ px: 2.5, pb: 3, }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}> 
          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6" style={{textTransform:"uppercase"}}>
              UPGRADE TO PREMIUM
            </Typography> 
          <Button color="success" onClick={()=>navigate("/dashboard/upgradetopro")} variant="contained">
         Click here
          </Button>
          </Box> 
        </Stack>
      </Box>}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
