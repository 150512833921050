import { Box, Button, Card, Stack, TextField, Typography } from '@mui/material'
import { addDoc, collection } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import React,{useState} from 'react'
import { auth, db } from '../../../firebase';
import useGetVendorCommentsById from '../../../hooks/useGetVendorCommentsById';
import UserName from '../../../_mock/UserName';
import { fToNow } from '../../../utils/formatTime';

function SharePost({id, userId}) {

    const [post, setpost] = useState("")
    const [response, setresponse] = useState("")
    const [loading, setloading] = useState(false)
  
    
    const comments = useGetVendorCommentsById(id)
    const postIt =async () =>{
        setloading(true)
        const docRef = await addDoc(collection(db, "business", id, "comments"), 
         { post, userId : auth.currentUser.uid, createdAt : Date.now()}).then(()=>{
            setpost("")
            console.log("posted")
            setloading(false)
         });                
    }

    const respondIt =async (commentId) =>{
        setloading(true)
         await addDoc(collection(db, "business", id, "comments", commentId, "response"), 
         { post : response, userId : auth.currentUser.uid, createdAt : Date.now()}).then(()=>{
            setresponse("")
            console.log("posted")
            setloading(false)
         });                
    }
  return (
    <Stack>
   <Card sx={{p:3}}>
    <Box>
    <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <TextField
        fullWidth multiline rows={1}
        label="Share your thoughts"
        value={post} disabled={loading}
        onChange={(event) => setpost(event.target.value)}
      />
 
        <LoadingButton loading={loading} variant="contained" color="warning" onClick={()=>postIt()}>Post</LoadingButton>
  
        </Stack>
         
    </Box>
   </Card>
<br/>
{comments?.map((c, index)=>(
    <Card sx={{p:3, mb:4}} key={index}>
    <Box>
        <Stack>
        <UserName id={c.userId} />
        <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
        <Typography>{c.post}</Typography>
        <Typography sx={{my:1, textAlign:"right"}} variant='overline'>{fToNow(c.createdAt)}</Typography>
     
        </Stack>
         </Stack>
      
         </Box>
        </Card>
))}
   </Stack>
  )
}

export default SharePost