 import  {useState, useEffect} from 'react'
import { doc, onSnapshot } from 'firebase/firestore';
 
import {  db } from '../firebase';

const useGetUser = (id) => {
   const [user, setUser] = useState(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if(id === undefined)
    {
      return
    }
   onSnapshot(doc(db, "app_users", id), (doc) => {
    setUser({id: doc.id, ...doc.data()})       
      });     
  }, [id])

  return user !== null ? user : null;
};


export default useGetUser

 