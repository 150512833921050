import { filter } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener, Card } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// component
import Iconify from '../../../components/Iconify';
import useGetDirectory from '../../../hooks/useGetDirectory';
import SearchResults from '../../../pages/SearchResults';
import ViewBusinesses from '../../../pages/ViewBusinesses';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
   
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {

  const navigate = useNavigate()
  const [open, setOpen] = useState(false); 
const [order, setOrder] = useState('asc');
const [orderBy, setOrderBy] = useState('name');
const [filterName, setFilterName] = useState(null);
  const handleOpen = () => {
    setOpen(!open);
  }; 
  const handleClose = () => {
    setFilterName(null)
    setOpen(false);
  };

  const results = useGetDirectory()?.filter((d)=>d.status === "APPROVED") ;

  // const searchButton = () =>{
  //   if(searchQuery.length === 0 ){return}
  //   navigate("/dashboard/search", {state: searchQuery})
  // }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
    //   const asd = filter(array, (_user) => _user.tags?.join(', ').toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
    //   filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
    //  filter(array, (_user) => _user.category.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    //   console.log(asd)
    const matchingUsers = [];
    const queryLowerCase = query.toLowerCase();

    array.forEach((_user) => {
      const tags = _user.tags ? _user.tags.join(', ').toLowerCase() : '';
      const name = _user.name.toLowerCase();
      const category = _user.category.toLowerCase();

      if (tags.indexOf(queryLowerCase) !== -1 || name.indexOf(queryLowerCase) !== -1 || category.indexOf(queryLowerCase) !== -1) {
        matchingUsers.push(_user);
      }
    });
      return matchingUsers
    }
    return stabilizedThis.map((el) => el[0]);
  }

 
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const filteredUsers = results && applySortFilter(results, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers?.length === 0;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
 
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              disableUnderline onChange={(event)=>handleFilterByName(event)}
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            /> 
            <Button   color='warning' onClick={handleClose}>X</Button>
          </StyledSearchbar>
        </Slide>
        <Card style={{width:"100%", position:"absolute", left:0, right:0,   }}>
      {filterName?.length > 0 && <ViewBusinesses USERLIST={filteredUsers} term={filterName}/>}
        </Card>
      </div>
    </ClickAwayListener>
  );
}
