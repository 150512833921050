import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithCredential, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import * as Yup from 'yup'; 
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment, Button, Typography, Divider } from '@mui/material';
import { doc, getDoc, setDoc } from 'firebase/firestore';

import { LoadingButton } from '@mui/lab';
import {db, auth} from "../../../firebase"
import GoogleSignIn from './GoogleSignIn';
// components

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();

  const [data, setdata] = useState({})
  const [code, setCode] = useState("");
  const [loading, setloading] = useState(false)
  const [verificationId,setVerificationId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);


  const RegisterSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Full name required'),
      phoneNumber: Yup.number().required('Last name required'),
  });

  const formik = useFormik({
    initialValues: {
      fullName: '',      
      phoneNumber : "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      setloading(true)
      console.log(values)
      setdata(values)
      const recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
        'size': 'normal',
        callback: (response) => {
          console.log(response);
        
          signInWithPhoneNumber(auth, `+260${values.phoneNumber}`, recaptchaVerifier)
            .then((confirmationResult) => {
              // SMS sent. Save the verification ID for later use.
              setVerificationId(confirmationResult.verificationId);
              setloading(false)
              console.log(confirmationResult.verificationId);
            })
            .catch((error) => {
              // Error; SMS not sent
              console.error(error);
              setloading(false)
              setErrorMessage('Error: SMS not sent');
            });
        },
        'expired-callback': () => {
          setloading(false)
          alert('reCAPTCHA expired');
        },
      });
     recaptchaVerifier.render()
    }
  });

  const verifyCode = () => {
    if (code.length !== 6) {
      setErrorMessage('Invalid verification code');
      return;
    }

    setloading(true)
    const createUser = async (userId)  =>{
      const currentDate = Date.now(); 
      const asd =await getDoc(doc(db, "app_users", userId))    
      console.log(asd)     
            if (asd.exists()) {
              navigate("/")
              // User data exists, do something
            } else {
              // User data does not exist, navigate to registration page
              setDoc(doc(db, 'app_users', userId), {
                ...data, 
                createdAt: currentDate,
                account : "FREE",
                account_status : "APPROVED",
                duration: 365,
                accountUpdatedOn :`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`
              })
                .then(() => {
                  console.log('Added data to the database');
                  navigate("/")
                   window.location.reload()
                })
                .catch((e) => console.log(e));
            }
    }


    const credential = PhoneAuthProvider.credential(verificationId, code);
    signInWithCredential(auth, credential)
      .then((result) => {
        console.log(result.user.uid)
        // User signed in successfully
        const userId = result.user.uid;
        createUser(userId)
          
      })
      .catch((error) => {
        // Error; Invalid verification code
        console.error(error);
        setErrorMessage('Error: Invalid verification code');
      });
  };

  const { errors, touched, handleSubmit,  getFieldProps } = formik;



  return (
    <>
    
    {!loading && <GoogleSignIn />}<br/>
    <Divider>Or your Phone Number</Divider>
   {!verificationId && <FormikProvider value={formik}>
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3} style={{padding:20}}>
        <Stack direction={{ xs: 'column', }} spacing={2}>
          <TextField
            fullWidth
            label="Full name"
            {...getFieldProps('fullName')}
            error={Boolean(touched.fullName && errors.fullName)}
            helperText={touched.fullName && errors.fullName}
          />

          <Stack direction={"row"} justifyContent={"center"} alignItems="center">
          <Typography variant='overline' style={{fontSize:20, marginRight:10}}>+260</Typography>
          <TextField
            fullWidth
            type='number'
            label="Phone Number"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />
          </Stack>
      
        </Stack>
        <div id="sign-in-button" style={{width:"100%"}} />

        <LoadingButton
        loading={loading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="warning"
          
        >
          Register
        </LoadingButton>

    
      </Stack>
    </Form>
    
  </FormikProvider>}
 
  {verificationId && <div style={{padding:20}}>
      <TextField
        fullWidth
        autoComplete="off"
        type="number"
        label="Verification Code"
        value={code}
        onChange={(event) => setCode(event.target.value)}
      /><br/> <br/>
      <LoadingButton loading={loading} type="submit" fullWidth   size="large" variant="outlined" color="warning" onClick={verifyCode}>Verify Code</LoadingButton>
      </div>}<br/><br/>
      {errorMessage && <p>{errorMessage}</p>}
    </>
  );
}
