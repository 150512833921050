import { Navigate, useRoutes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SearchResults from './pages/SearchResults'; 
import RegisterPage from './pages/RegisterPage';
import ProfilePage from './pages/ProfilePage';
import UpgradePro from './pages/UpgradePro';
import AddBusiness from './sections/profile/AddBusiness';
import VendorPage from './sections/@dashboard/products/VendorPage';
import UploadImages from './sections/@dashboard/album/UploadImages';
import NEwProjects from './sections/@dashboard/projects/NEwProjects';
import ManageProject from './sections/@dashboard/projects/ManageProject';
import ManagePage from './sections/@dashboard/products/ManagePage';
import EditPage from './sections/@dashboard/products/EditPage';
import Marketplace from './pages/Marketplace';
import ViewBlogPost from './sections/@dashboard/blog/ViewBlogPost';
import Inbox from './pages/Inbox';
import Explore from './pages/Explore';
import Payment from './pages/Payment';
import Onboarding from './pages/Onboarding';
import UserProfile from './sections/profile/UserProfile';
import CreateSite from './sections/@dashboard/sites/CreateSite';
import EventsPage from './pages/EventsPage';
import EditProfile from './sections/profile/EditProfile';
import AddBusiness2 from './sections/profile/AddBusiness2';
import Admin from './pages/Admin';
import { auth } from './firebase';
import useGetUser from './hooks/useGetUser';
import Loading from './_mock/Loading';

// ----------------------------------------------------------------------

export default function Router() {

  const [user, setUser] = useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      if (u) {       
        setUser({state:true, data: u.uid});
      } else {      
        setUser(false);
      }
    });
  }, []);

  const user_ = useGetUser(user?.data)
  useEffect(() => {
  //   if(Object.keys(user_)?.length  === 1){
  //     signOut(auth).then(() => {
  //       window.location.reload() 
  //     }).catch((error) => {
  //       console.log(error)
  //       // An error happened.
  //     });
  // }
  
  }, [user_])
  
   
  
  const routes = useRoutes([
    {
      path: '/dashboard',
       element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
       { path: 'app', element: <ProductsPage /> },
        { path: 'events', element: <Marketplace /> }, 
        { path: 'blog', element: <BlogPage /> },
        { path: 'search', element: <SearchResults /> },
        { path: 'upgradetopro', element: <UpgradePro /> },
        { path: 'admin', element: user_?.admin ? <Admin /> : <Loading /> },
        { path: 'profile', element: <ProfilePage /> },
        { path: 'editProfile', element: <EditProfile /> },
        { path: 'addBusiness', element: <AddBusiness2 /> },
        { path: 'vendorPage', element: <VendorPage /> },
        { path: 'uploadImages/:id', element: <UploadImages /> },
        { path: 'viewProject', element: <ManageProject /> },
        { path: 'managePage', element: <ManagePage /> },
        { path: 'editPage', element: <EditPage /> },
        { path: 'inbox', element: <Inbox /> },
        { path: 'viewBlogPost', element: <ViewBlogPost /> }, 
        { path: 'explore', element: <Explore /> }, 
        { path: 'payment', element: <Payment /> }, 
        { path: 'userProfile', element: <UserProfile /> }, 
       
     ],
   }, 
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: '*',
      element: <Navigate to="/dashboard" replace />,
    },
  ]);

  return routes ;
}
