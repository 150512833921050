import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import { useSnackbar } from 'notistack'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack, 
  Button, 
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,  
} from '@mui/material';
// components 
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; 
import Page from '../components/Page';
import SearchNotFound from '../components/SearchNotFound';
import {UserListToolbar, UserListHead } from '../sections/@dashboard/user';
import Scrollbar from '../components/Scrollbar';
import useResponsive from '../hooks/useResponsive';
 
 
const TABLE_HEAD = [
  
  { id: 'name', label: 'Company Name', alignRight: false }, 
  { id: 'category', label: 'Category', alignRight: false },   
  { id: '' },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ViewBusinesses({USERLIST}) {
  const { enqueueSnackbar } = useSnackbar() 
  const isDesktop = useResponsive('up', 'lg');
  // const USERLIST = useGetDirectory()?.sort((a, b) => a.name.localeCompare(b.name)).filter((d)=>d.businessType !== "SERVICES")
  const [selectedfilter, setselectedfilter] = useState("ALL")

  const [filtered, setfiltered] = useState([])
  const filterByStatus = (status) =>{
    setselectedfilter(status)
    const result = USERLIST?.filter((b)=>b.status === status)
    setfiltered(result)
  }

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('plate');

  const [filterName, setFilterName] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(isDesktop ? 10 : 5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST?.map((n) => n.plate);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST?.length) : 0;

  const filteredUsers = USERLIST && applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers?.length === 0;

  const navigate = useNavigate()
  const [selectedBus, setselectedBus] = useState(null)

  const deleteBus =async () =>{      
    await deleteDoc(doc(db, "buses", selectedBus)).then(()=>{
      enqueueSnackbar("Bus deleted successfully.")
      handleClose()
    }).catch(()=>{
      enqueueSnackbar("Failed to delete the bus. Try again later. ")
    }) 
}

const [open, setOpen] = useState(false);
 
const handleClose = () => {
  setOpen(false);
};

 
  return (
      
        <Card sx={{marginTop:5, }}>
          {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

          <Scrollbar>
            <TableContainer sx={{  }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, category,  } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      > 
                        <TableCell align="left"  > </TableCell> 
                        <TableCell align="left"  >{name}</TableCell> 
                        <TableCell align="left"  >{category}</TableCell> 
                        <TableCell align="right" >
                        <Button variant='text' href={`https://findmezm.com/dashboard/vendorPage?id=${row.id}`}  >
                     View
                        </Button>
                        </TableCell> 
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[7, 15, 20]}
            component="div"
            count={USERLIST?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      
  
  );
}
 