import  {useState, useEffect} from 'react'
import { collection,  onSnapshot, query } from 'firebase/firestore';
 
import {  db,} from '../firebase';

function useGetMarketPlace() {

    
        const [user, setuser] = useState(null)
        useEffect(() => {
            const q = query(collection(db, "events"))
            onSnapshot(q, (querySnapshot) => {        
             const data =[]
             querySnapshot.forEach((doc) => {
                 const asd = {
                   ...doc.data(),
                   id: doc.id
                 }
                data.push(asd)      
               })
               
               setuser(data)
              });
        }, [])
      return user
    }

export default useGetMarketPlace