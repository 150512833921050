import { Avatar, Stack, Typography } from '@mui/material'
import React from 'react' 
import useGetBusiness from '../hooks/useGetBusiness'
import useResponsive from '../hooks/useResponsive';

function BusinessName({id}) {

  const isDesktop = useResponsive('up', 'lg');
    const user = useGetBusiness(id)
  return (
    user && 
    <Stack direction={"row"} alignItems={"center"}>
    <Avatar src={user.cover_image}  /> 
    {isDesktop && <Typography sx={{my:2, ml:2}} variant="overline"> {user.name}</Typography>}

    </Stack>
  )
}

export default BusinessName