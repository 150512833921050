import { useNavigate } from 'react-router-dom'
import React from 'react' 
import { deleteDoc, doc } from 'firebase/firestore'
import { Box, Button, Card, Grid, Stack, Typography, styled } from '@mui/material'
import useGetBusiness from '../../../hooks/useGetBusiness'
import { auth, db } from '../../../firebase'
import Label from '../../../components/label'

function Business({id}) {
    const b = useGetBusiness(id)
    const navigate = useNavigate()
 
    async function  deleteFavorite(){
      await deleteDoc(doc(db, 'app_users', auth.currentUser.uid, "likes", id)).then(()=>{
          console.log("item removed")
      })
}

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

  return (
    <Grid item xs={12} md={4}>
   
    <Card>
      <Box  onClick={()=>navigate(`/dashboard/vendorPage?id=${id}`, {state : b})} sx={{ pt: '100%', position: 'relative' }}>
       
          <Label
            variant="filled"
            color={'warning'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {b?.category}
          </Label>
      <StyledProductImg alt={b?.name} src={b?.cover_image} />
      </Box>
      <Stack spacing={2} justifyContent={"space-between"} direction="row" sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            {b?.name}
          </Typography> 
       </Stack>
       <Stack spacing={2} justifyContent={"space-between"} direction="row" sx={{ p: 3 }}>
          <Button color="error" fullWidth onClick={()=>deleteFavorite(id)}>Remove</Button>     
          </Stack>
    </Card>
  
</Grid>
  )
}

export default Business