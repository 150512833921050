import React from 'react'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import useGetVendorFilesById from '../../../hooks/useGetVendorFilesById'

function ViewPricing({id}) {
    const files = useGetVendorFilesById(id)
  return (
   <Stack>
        {files && files.map((f, index)=>(
            <Card sx={{p:3, mb:2}} key={index}>
                <Box>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>{f.post}</Typography>
                        <Button variant='outlined' color='success' href={f.file} target='_blank' >View</Button>
                    </Stack>
                </Box>
            </Card>
        ))}
   </Stack>
  )
}

export default ViewPricing