import  {useState, useEffect} from 'react'
import { collection,  getDocs,  onSnapshot, query, where } from 'firebase/firestore';
 
import {  db,} from '../firebase';

function useGetAlbumById(albumId) {

    
        const [user, setuser] = useState(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect( () => {
          if( albumId === null || albumId === undefined){
            return
          }
          const asyncfn = async ()=>{
       
            const querySnapshot = await getDocs(collection(db, "business", albumId, "albums"));
            const data =[]
            querySnapshot.forEach((doc) => {
                const asd = {
                  ...doc.data(),
                  id: doc.id
                }
               data.push(asd)      
              })
              
              setuser(data)

          }
          asyncfn()
        }, [albumId])
      return user
    }

export default useGetAlbumById