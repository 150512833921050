import { Helmet } from 'react-helmet-async';
// @mui
import { Button,  Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import { useLocation, useNavigate } from 'react-router-dom';
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

import vendorCategories  from '../../../_mock/categories';
import services  from '../../../_mock/services';
import { auth, db } from '../../../firebase';
import UploadPricing from './UploadPricing';

import UpdateCoverImage from './UpdateCoverImage';
import useGetBenefits from '../../../hooks/useGetBenefits';
import provinces from '../../../data/provinces.json'
import towns from '../../../data/towns.json'
import townships from '../../../data/townships.json'
import useGetBusiness from '../../../hooks/useGetBusiness';
 
export default function   EditPage() { 
 
    const location = useLocation()
    // const data = location.state
 const navigate = useNavigate()

    const data = useGetBusiness(location.state.id)
 
  const benefits = useGetBenefits()
    const [province, setprovince] = useState(null)
    const [town, settown] = useState(null)
    const [township, settownship] = useState(null)
    const [docId, setdoc] = useState(null)
const [loading, setloading] = useState(false)
const [provinces_, setprovinces] = useState([])
const [towns_, settowns] = useState([])
const [townships_, settownships] = useState([]) 

const initialValues = {      
    name: data?.name ?? null,
    phone: data?.phone ?? null,    
    tags: data?.tags ? data.tags.join(", ") : "", 
    province: data?.province ?? null,  
    township : data?.township ?? null,
    town: data?.town ?? null,    
    address: data?.address ?? null,  
    category : data?.category ?? null,
    businessType : data?.businessType ?? null,
    description : data?.description ?? null,
    companyType : data?.companyType ?? null,
    pacra: data?.pacra ?? null,
    facebook: data?.facebook ?? null,
    instagram: data?.instagram ?? null,
    x: data?.x ?? null,
    linkedIn: data?.linkedIn ?? null,
    youtube: data?.youtube ?? null,
    tiktok : data?.tiktok ?? null,
    whatsapp: data?.whatsapp ?? null,
    website: data?.website ?? null,
    maps: data?.maps ?? null,
    startTime : data?.startTime ? data?.startTime : null,
    closeTime : data?.closeTime ? data?.closeTime : null
  };
 
  useEffect(() => {
    getProvinces()
  }, [])
  
  const getProvinces = ()=>{ 
    setprovinces(provinces)  
  }   
 
  useEffect(() => {
   if(province) {
     getTowns()
   }
  }, [province])
  
  const getTowns = ()=>{ 
      const result = towns.filter((item)=> item.prov_code === province?.code)

    settowns(result)
    console.log(result);
  }

  useEffect(() => {
    if(town){
      getTownShips()
 }
  }, [town])
  
  const getTownShips = async()=>{ 
    const uniqueArray = townships.filter((v,i,a)=>a.findIndex(t=>(t.township_name === v.township_name))===i);
    const result = uniqueArray.filter((item)=> item.town_code === town?.code)

    settownships(result)
     
  }
 
 
const [open, setOpen] = useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const deleteBusiness =async()=>{ 
    await deleteDoc(doc(db, 'business', location.state.id)).then(()=>{
      navigate(-2)
    }) 
}
  return (
   <>
      <Helmet>
        <title> Edit Business</title>
      </Helmet>

      <Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"You are about to Delete your business"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting your your business will completely remove it from the database.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={deleteBusiness} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <>
      <Helmet>
        <title>Edit Business</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h6" gutterBottom>
            Edit Business
          </Typography>      
          <Button variant='contained' color="error" onClick={()=>handleClickOpen()}>Delete Business</Button>   
        </Stack>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                  {data && 
                  <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        // validationSchema={validationSchema}
                        onSubmit={async (values,  { resetForm }) => {
                             
                        console.log(initialValues ); 
                        
                        if(values.companyType === "COMPANY" && !values.pacra ){
                            alert("Enter your Company PACRA Number")
                            return
                          }
                        if(values.companyType === "BUSINESS_NAME" && !values.pacra ){
                            alert("Enter your Company PACRA Number")
                            return
                          }
                          if(values.pacra?.length <5)
                          {
                            alert("Enter a valid Pacra Number")
                            return
                          }
                          setloading(true)
                        const userDocRef = doc(db, 'business', data.id);

                     await updateDoc(userDocRef,  {...values, 
                      pacra: (values.companyType === "COMPANY" || values.companyType === "BUSINESS_NAME") ? values.pacra : null,
                     province: township?.prov_name === undefined ? data.province :  township?.prov_name , 
                     town : township?.town_name === undefined ? data.town :  township?.town_name , 
                     township : township?.township_name === undefined ? data.township :  township?.township_name ,
                      tags : values.tags.split(", "),  updatedAt : Date.now()}).then(()=>{
                      setdoc(userDocRef.id)
                            console.log("Document updated with ID: ", userDocRef.id);
                            setloading(false)
                          
                     }).catch((error)=>{
                        console.log(error)
                     });
                       
                      
                                    }}
                    >
                        {(formik) => (
                        <Form>
                        
                            <Field
                            hiddenLabel
                            as={TextField}
                            fullWidth
                              type="text"
                            name="name" 
                            label="Business/Company Name"
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            /> <br/><br/>
                            <Field
                            fullWidth  
                            as={TextField}
                            name="phone"   
                            type="number"           
                            label="Phone Number"
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                            /> <br/><br/>
                            <Field
                            fullWidth multiline rows={3}
                            as={TextField}
                            name="description"              
                            label="Description"
                            error={formik.touched.description && Boolean(formik.errors.description)}
                            helperText={formik.touched.description && formik.errors.description}
                            /> <br/><br/>
                             {<TextField
            fullWidth select          
            label="Select your province"
            value={province}
            onChange={(event) => setprovince(event.target.value)}
          >
          {provinces?.sort((a, b) => a.prov_name.localeCompare(b.prov_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.prov_name}</MenuItem>
          ))}
          </TextField>}
        
          {province && <TextField
            fullWidth select          
            label="Select your Town"
            value={town}
            onChange={(event) => settown(event.target.value)}
          >
          {towns_?.sort((a, b) => a.town_name.localeCompare(b.town_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.town_name}</MenuItem>
          ))}
          </TextField>}
         
          {town && <TextField
            fullWidth select          
            label="Select your Township"
            value={township}
            onChange={(event) => settownship(event.target.value)}
          >
          {townships_?.sort((a, b) => a.township_name.localeCompare(b.township_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.township_name}</MenuItem>
          ))}
          </TextField>}<br/><br/>
                    
          <Field
                            fullWidth 
                            as={TextField}
                            name="businessType"
                            select
                            label="Type of Business"
                            error={formik.touched.businessType && Boolean(formik.errors.businessType)}
                            helperText={formik.touched.businessType && formik.errors.businessType}
                            > 
                                           <MenuItem value={"HOTSPOTS&PLACES"}>Hotspots & Places</MenuItem> 
                              <MenuItem value={"SERVICES"}>Service</MenuItem>     
                            </Field> <br/><br/>

                            <Field
                            fullWidth 
                            as={TextField}
                            name="category"
                            select
                            label="Category"
                            error={formik.touched.category && Boolean(formik.errors.category)}
                            helperText={formik.touched.category && formik.errors.category}
                            > 
                            {formik.values?.businessType === "SERVICES" ? services.map((c, index)=>(
                            <MenuItem value={c.category} key={index}><Stack>
                                <Typography>{c.category}</Typography>
                            </Stack></MenuItem>
                    )):   vendorCategories?.sort((a, b) => a.category.localeCompare(b.category)).map((c, index)=>(
                            <MenuItem value={c.category} key={index}><Stack>
                                <Typography>{c.category}</Typography>
                            </Stack></MenuItem>
                    ))}
                            </Field> <br/><br/>
                            <Field
                            fullWidth 
                            as={TextField}
                            name="companyType"
                            select
                            label="Company Type"
                            error={formik.touched.companyType && Boolean(formik.errors.companyType)}
                            helperText={formik.touched.companyType && formik.errors.companyType}
                            > 
                          
                            <MenuItem value={"COMPANY"}>COMPANY</MenuItem>
                            <MenuItem value={"BUSINESS_NAME"}>BUSINESS NAME</MenuItem>
                            <MenuItem value={"SELF_EMPLOYED"}>SELF EMPLOYED</MenuItem>
              
                            </Field> <br/><br/>
                            {(formik?.values?.companyType === "COMPANY" || formik?.values?.companyType === "BUSINESS_NAME") && 
              <Stack>  <Field
                            fullWidth 
                            as={TextField}
                            name="pacra" type="number"
                                label="PACRA Number"
                            error={formik.touched.pacra && Boolean(formik.errors.pacra)}
                            helperText={formik.touched.pacra && formik.errors.pacra}
                            />
          <Typography>Please note that this is for Authentication purposes only.</Typography>
          </Stack>
               }
                            <Field
                            fullWidth 
                            as={TextField}
                            name="address"
                                label="Address"
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            /> <br/><br/>   
                            <Field
                            fullWidth 
                            as={TextField}
                            name="facebook"
                                label="Facebook"
                            error={formik.touched.facebook && Boolean(formik.errors.facebook)}
                            helperText={formik.touched.facebook && formik.errors.facebook}
                            /> <br/><br/>   
                            <Field
                            fullWidth 
                            as={TextField}
                            name="instagram"
                                label="Instagram"
                            error={formik.touched.instagram && Boolean(formik.errors.instagram)}
                            helperText={formik.touched.instagram && formik.errors.instagram}
                            /> <br/><br/>   
                            <Field
                            fullWidth 
                            as={TextField}
                            name="x"
                                label="X"
                            error={formik.touched.x && Boolean(formik.errors.x)}
                            helperText={formik.touched.x && formik.errors.x}
                            /> <br/><br/>   
                            <Field
                            fullWidth 
                            as={TextField}
                            name="whatsapp" type="number"
                                label="WhatsApp for Business"
                            error={formik.touched.whatsapp && Boolean(formik.errors.whatsapp)}
                            helperText={formik.touched.whatsapp && formik.errors.whatsapp}
                            /> <br/><br/>      
                            <Field
                            fullWidth 
                            as={TextField}
                            name="youtube" type="text"
                                label="YouTube"
                            error={formik.touched.youtube && Boolean(formik.errors.youtube)}
                            helperText={formik.touched.youtube && formik.errors.youtube}
                            /> <br/><br/>   
                            <Field
                            fullWidth 
                            as={TextField}
                            name="linkedIn" type="text"
                                label="LinkedIn"
                            error={formik.touched.linkedIn && Boolean(formik.errors.linkedIn)}
                            helperText={formik.touched.linkedIn && formik.errors.linkedIn}
                            /> <br/><br/>   
                            <Field
                            fullWidth 
                            as={TextField}
                            name="tiktok"
                                label="TikTok"
                            error={formik.touched.tiktok && Boolean(formik.errors.tiktok)}
                            helperText={formik.touched.tiktok && formik.errors.tiktok}
                            /> <br/><br/>   
                                
                                <Stack direction={"row"} spacing={3} justifyContent={"space-between"}>
                                <Field
                            fullWidth 
                            as={TextField}
                            name="startTime" type="time"
                                label="Opening Time"
                            error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                            helperText={formik.touched.startTime && formik.errors.startTime}
                            />
                               <Field
                            fullWidth 
                            as={TextField}
                            name="closeTime" type="time"
                                label="Closing Time"
                            error={formik.touched.closeTime && Boolean(formik.errors.closeTime)}
                            helperText={formik.touched.closeTime && formik.errors.closeTime}
                            />
          </Stack>
          <br/>
                                <Field
                            fullWidth 
                            as={TextField}
                            name="tags"
                                label="Services (separated by commas)"
                            error={formik.touched.tags && Boolean(formik.errors.tags)}
                            helperText={formik.touched.tags && formik.errors.tags}
                            /> <br/>     <br/>  
                                <Field
                            fullWidth 
                            as={TextField}
                            name="maps"
                                label="Copy and paste your Google Maps Address"
                            error={formik.touched.maps && Boolean(formik.errors.maps)}
                            helperText={formik.touched.maps && formik.errors.maps}
                            /> <br/>         <br/>   

                                
                                <LoadingButton loading={loading} variant='contained'   size={"large"} color="warning" disabled={!formik.isValid} type='submit'>Update</LoadingButton>  
                
                            </Form>
                        )}
                            </Formik>} 
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <UploadPricing id={data?.id} pricingGuides={benefits?.features?.pricingGuides} />
                    <UpdateCoverImage id={data?.id} data={data} />
                    </Grid>
             
                </Grid> 
      </Container>
    </>
      </Container>
    </>
  );
}
 