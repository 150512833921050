import { useState } from 'react'; 
import { Stack, Container, Typography, Grid, Card, Box, Tabs, Tab } from '@mui/material';
import Free from './Free';

function Basic() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
     
    
    
   
      <Container sx={{mt:3}}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h6" gutterBottom>
            Insights
          </Typography>         
        </Stack>

   
        <Grid container spacing={3}>
            
            <Grid item sx={12} md={4}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Reach</Typography> 
            <Typography variant="body1" gutterBottom>Set your goals, track progress and achieve them</Typography> 
                
            <Typography variant="h1" color={"primary"} gutterBottom>12<br/>
            <Typography variant="h6" gutterBottom>Reach</Typography> </Typography>  
         
           </Box>
           </Card>  
            </Grid>
         
            <Grid item sx={12} md={4}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Engagement</Typography> 
            <Typography variant="body1" gutterBottom>You published 49 pieces of content this past week.</Typography> 

            <Typography variant="h1" color={"secondary"} gutterBottom>44<br/>
            <Typography variant="h6" gutterBottom>Engagement</Typography> </Typography> 

           </Box>
           </Card>  
            </Grid>

            <Grid item sx={12} md={4}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Profile Views</Typography> 
            <Typography variant="body1" gutterBottom>See your overall reach from last week.</Typography> 
            <Typography variant="h1" color={"secondary"} gutterBottom>567<br/>
            <Typography variant="h6" gutterBottom>Views</Typography> </Typography> 
           </Box>
           </Card> 
            </Grid>

            <Grid item sx={12} md={4}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Recommendations</Typography> 
            <Typography variant="body1" gutterBottom>Track your progress toward your business messaging goals</Typography>
            <Typography variant="h1" color={"secondary"} gutterBottom>414<br/>
            <Typography variant="h6" gutterBottom>Recommendations</Typography> </Typography>  
           </Box>
           </Card> 
            </Grid>

            <Grid item sx={12} md={4}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Favorites</Typography> 
            <Typography variant="body1" gutterBottom>Track your progress toward your business messaging goals</Typography>
            <Typography variant="h1" color={"secondary"} gutterBottom>414<br/>
            <Typography variant="h6" gutterBottom>Favorites</Typography> </Typography>  
           </Box>
           </Card> 
            </Grid>
           
            <Grid item sx={12} md={4}>
            <Card sx={{p:3}}>
          <Box>
            <Typography variant="h6" gutterBottom>Interaction Score</Typography> 
            <Typography variant="body1" gutterBottom>Track your progress toward your business messaging goals</Typography>
            <Typography variant="h1" color={"secondary"} gutterBottom>414<br/>
            <Typography variant="h6" gutterBottom>Points</Typography> </Typography>  
           </Box>
           </Card> 
            </Grid>
        </Grid>
        
      </Container>
    </div>
  )
}

export default Basic