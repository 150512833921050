import { Helmet } from 'react-helmet-async';
import { TextField, Container, Typography, Grid, Table, TableBody, TableCell, TableRow, Divider, Stack, MenuItem, Button } from '@mui/material';
import { useState } from 'react';
 
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import UserName from '../../../_mock/UserName';

// ----------------------------------------------------------------------

export default function Transactions({data}) {
 
  const [search, setSearch] = useState("")
  const [filtered, setfiltered] = useState(null)
  const [selectedItem, setselectedItem] = useState(null)
  const [foundUser, setfoundUser] = useState(null)
  const [account, setAccount] = useState(null);

  const searchBox = (s) =>{
    setSearch(s)
     const filtered = data?.filter((item)=> item.reference === s)
     if(filtered.length > 0){
       setfiltered(filtered)
     }
     else{
      setfiltered(null)
     }
  }

  const updateProfile = async () =>{ 
    const obj = {
      lastUpdate : Date.now(),
      account,
      account_status : "APPROVED",
      duration : 365,
      accountUpdatedOn : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`

    }

    const accountUpdatedDate =  new Date(obj.accountUpdatedOn);
    accountUpdatedDate.setDate(accountUpdatedDate.getUTCDate() + obj.duration);
    
    await updateDoc(doc(db, "app_users", selectedItem.userId), {...obj,  accountUpdatedDate: `${new Date(accountUpdatedDate).getMonth()+1}/${new Date(accountUpdatedDate).getDate()}/${new Date(accountUpdatedDate).getFullYear()}`
        }).then(()=>{
       setAccount(null)
       setselectedItem(null)
    }); 
        }
  
        
        const handleAccountChange = (newValue) => {
          setAccount(newValue);
        };

  return (
   <>
      <Helmet>
        <title> Transactions</title>
      </Helmet>

      <Container>
      <Grid container spacing={3} >
        <Grid item xs={12} md={8}>
        <TextField
            fullWidth
            value={search}
            type='number'
            label="Search for TXN ID" 
            onChange={(event)=>searchBox(event.target.value)}
          />
 <Table>
    <TableBody>

    {filtered === null ? data?.map((d, index)=>(
        <TableRow key={index}>
        <TableCell><UserName id={d.userId} /></TableCell>
        <TableCell>{d.amount}</TableCell>
        <TableCell>{d.package === "PRO" ? "PREMIUM" : d.package}</TableCell>
        <TableCell>{d.reference}</TableCell>
        <TableCell>{new Date(d.createdAt).toLocaleDateString()}</TableCell>
        <TableCell> <Button color="warning" variant="contained" onClick={()=>setselectedItem(d)} >Edit</Button></TableCell>
    </TableRow>
    )) : filtered?.map((d, index)=>(
        <TableRow key={index}>
        <TableCell><UserName id={d.userId} /></TableCell>
        <TableCell>{d.amount}</TableCell>
        <TableCell>{d.package === "PRO" ? "PREMIUM" : d.package}</TableCell>
        <TableCell>{d.reference}</TableCell>
        <TableCell>{new Date(d.createdAt).toLocaleDateString()}</TableCell>
        <TableCell> <Button color="warning" variant="contained" onClick={()=>setselectedItem(d)} >Edit</Button></TableCell>
    </TableRow>
    ))}
    </TableBody>
 </Table>
        </Grid>
        <Grid item xs={12} md={4}>
        <Typography>Edit User Account</Typography>
    <Divider sx={{my:2}} />
    {selectedItem && <Stack spacing={2}>
    <Typography variant='h5' fontWeight={"bold"}>Amount {selectedItem.amount}</Typography>
    <Typography>Package {selectedItem.package}</Typography>
    <Typography>REf {selectedItem.reference}</Typography>
       <Divider sx={{my:2}} >Update Profile account</Divider>

    <TextField fullWidth label="Select account type"   select  value={account}
           onChange={(event) => handleAccountChange(event.target.value)}
          ><MenuItem value={"FREE"}><Typography>FREE</Typography></MenuItem>
          <MenuItem value={"BASIC"}><Typography>BASIC</Typography></MenuItem>
          <MenuItem value={"PRO"}><Typography>PREMIUM</Typography></MenuItem> 
          </TextField>
<Button variant="contained" color="success" onClick={()=>updateProfile()}>Update Account</Button>
    </Stack>} 
        </Grid>
      </Grid>
     
      </Container>
    </>
  );
}
