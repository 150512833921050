import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Grid,  Container, Stack, Typography, Button, Dialog, Card, Box } from '@mui/material';
// components
import { addDoc, collection, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { BlogPostCard } from '../sections/@dashboard/blog';
import CreatePost from '../sections/@dashboard/blog/CreatePost';
import useGetPosts from '../hooks/useGetPosts';
import { auth, db } from '../firebase';

export default function BlogPage() {
  const POSTS = useGetPosts()
  const [open, setOpen] = useState(false);

 
  const handleClose = () => {
    setOpen(false);
  };


  // reason for report : spam, use of profanity, containes absuive language or imagery
  return (
    <>
      <Helmet>
        <title>Share your experience</title>
      </Helmet>

   

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" >
          <Typography variant="h6" gutterBottom>
            Share your experience
          </Typography>    
        </Stack>
 
        <Grid container spacing={3} justifyContent={"center"}>
        
        <Grid item xs={12} md={6}>
        <Grid item xs={12} md={12} sx={{mb:2}}>

        {auth.currentUser !== null && <CreatePost handleClose={handleClose} />}
        
        </Grid>
          {POSTS && POSTS.map((post, index) => (
           <BlogPostCard post={post} key={index} />
          ))}
        </Grid>
        </Grid>
      </Container>
    </>
  );
}
