import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, Grid, Card, Divider, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import useGetUser from '../hooks/useGetUser';
import { fNumber } from '../utils/formatNumber';

function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  const user = useGetUser(auth.currentUser?.uid);
  const EXCHANGE_RATE = 1;

  const pay = async () => {
    await addDoc(collection(db, `invoices`), {
      amount: data.bill,
      userId: auth.currentUser.uid,
      package: data.package,
      duration: data.package === "BASIC" ? 30 : 365,
      createdAt: Date.now(),
    }).then(async () => {
      await updateDoc(doc(db, "app_users", auth.currentUser.uid), {
        account: data.package,
        duration: 0,
        account_status: "PENDING",
        accountUpdatedOn: `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`
      }).then(() => {
        console.log("account updated");
        // Navigate to your custom payment gateway page
        window.location.href = "/paymentgateway.html";
      });
    });
  };

  return (
    <>
      <Helmet>
        <title>Payment</title>
      </Helmet>

      <Container>
        <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
          {auth.currentUser && (
            <Grid item xs={12} md={6}>
              <Card sx={{ p: 3, textAlign: 'center' }}>
                <Stack spacing={2}>
                  <Typography variant="h5">Summary</Typography>
                  <Divider />
                  <Typography variant="h6">Package</Typography>
                  <Typography variant='h4'>{data.package}</Typography>
                  <Divider />
                  <Typography variant="h6">Duration</Typography>
                  <Typography variant='h4'>{data.package === "BASIC" ? "Monthly" : "Yearly"}</Typography>
                  <Divider />
                  <Typography variant="h6">Bill</Typography>
                  <Typography variant='h4'>K{fNumber(data.bill * EXCHANGE_RATE)}</Typography>
                  <Divider />
                  <Button variant='contained' onClick={pay} color='success' size="large">Proceed to Payment</Button>
                </Stack>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default Payment;
