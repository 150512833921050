import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable} from 'firebase/storage'
import * as yup from 'yup'; 
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField,  Typography,  MenuItem, Container, Grid, Card, CircularProgress, Button } from '@mui/material';
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';
 

import { LoadingButton } from '@mui/lab';
import {db, auth} from "../../firebase"
import useGetUser from '../../hooks/useGetUser';
import vendorCategories from '../../_mock/categories';
import services from '../../_mock/services';
import provinces from '../../data/provinces.json'
import towns from '../../data/towns.json'
import townships from '../../data/townships.json'
import useGetBenefits from '../../hooks/useGetBenefits';
import useGetBusinessById from '../../hooks/useGetBusinessById';

function AddBusiness2() {
    const data = null
    const userId = auth.currentUser?.uid
    const user = useGetUser(userId)
    const storage = getStorage()
  const navigate = useNavigate()  
  const [docId, setdoc] = useState(null)
  const [provinces_, setprovinces] = useState([])
  const [towns_, settowns] = useState([])
  const [townships_, settownships] = useState([])
  const [loading, setloading] = useState(false)
  const [province, setprovince] = useState(null)
  const [town, settown] = useState(null)
  const [township, settownship] = useState(null)
  const [images, setImages] = useState([]); 
  const [uploadProgress, setUploadProgress] = useState(0);

 
    
    const RegisterSchema = yup.object().shape({
        name: yup.string().required("Name is required"),
        phone: yup.number().required("Phone Number is required"),
        businessType: yup.string().required("Business Type is required"),
        address: yup.string().required("Address is required"),
        category: yup.string().required("Category is required"),
        description: yup.string().required("Description is required"),
        companyType: yup.string().required("Company Type is required"), 
   });  
      const initialValues= {

      }
      const formik = useFormik({
        initialValues,
        enableReinitialize: true,
          validationSchema: RegisterSchema,
          onSubmit: async (values) => {
            console.log("object", values)
          
            if(values.companyType === "COMPANY" && !values.pacra ){
              alert("Enter your Company PACRA Number")
              return
            }
            if(values.companyType === "BUSINESS_NAME" && !values.pacra ){
              alert("Enter your Company PACRA Number")
              return
            }
            if(values.pacra?.length <5)
            {
              alert("Enter a valid Pacra Number")
               return
            }
            setloading(true)
            const data = {
                ...values, 
                  companyType: values.companyType,
                  pacra: (values.companyType === "COMPANY" || values.companyType === "BUSINESS_NAME" ) ? values.pacra : null,
                  province: township?.prov_name || null, 
                town : township?.town_name || null, 
                township : township?.township_name || null,
                status: "PENDING",
            }
            console.log(data)
             
            const docRef = await addDoc(collection(db, "business"), {...data, 
              tags : values.tags ? values.tags?.split(", ") : null, userId : auth.currentUser.uid, createdAt : Date.now()}).catch((e)=>console.log((e))) 
               setdoc(docRef.id)
                   console.log("Document written with ID: ", docRef.id);
                   setloading(false) 
           }
      });
    
 
      const { errors, touched, isValid, handleSubmit,  getFieldProps, values } = formik;
      
      useEffect(() => {
        getProvinces()
      }, [])
      
      const getProvinces = ()=>{ 
        setprovinces(provinces) 
        console.log(provinces)
      }   
     
      useEffect(() => {
       if(province) {
         getTowns()
       }
      }, [province])
      
      const getTowns = ()=>{ 
          const result = towns.filter((item)=> item.prov_code === province?.code)
    
        settowns(result)
        console.log(result);
      }
    
      useEffect(() => {
        if(town){
          getTownShips()
     }
      }, [town])
      
      const getTownShips = async()=>{ 
        const uniqueArray = townships.filter((v,i,a)=>a.findIndex(t=>(t.township_name === v.township_name))===i);
    const result = uniqueArray.filter((item)=> item.town_code === town?.code)

    
        settownships(result)
         
      }
    
      const saveProfile = () =>{
       
        handleUpload()
     }
    
     const handleUpload = () => {
       
      if (images.length === 0) {
        alert("select an image to upload")
        // No image selected
        return;
      }
    
      const image = images[0];
      uploadImage(image) 
    };
    
    const uploadImage = async (image) => {   
      setloading(true) 
          const id = Date.now();
          const fileRef = ref(storage, `business/${image.name}_${id}`);
          const uploadTask = uploadBytesResumable(fileRef, image);
          uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress)
            setUploadProgress(progress);
          },
          (error) => {
            console.error(`Error uploading ${image.name}: ${error.message}`); 
          },
          () => {
            // Upload completed successfully
            getDownloadURL(fileRef).then(async (downloadURL) => {
              const userDocRef = doc(db, 'business', docId);
              doc(db, 'business', docId);
                     await updateDoc(userDocRef, {
                     cover_image: downloadURL,
                     });
    });
    setImages([])
    setUploadProgress(0)
    setloading(false)
    navigate("/dashboard/profile")
          }
        ); 

    };
    
    
    const handleImageChange = (event) => {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        setImages([file]);
      }
    };
    const businesses = useGetBusinessById(auth.currentUser?.uid)
    const benefits =  useGetBenefits()
 console.log(values)
  return (
     <>
      <Helmet>
        <title> Add Business</title>
      </Helmet>

      {benefits && businesses && businesses?.length < benefits?.features?.businesses &&  user?.account_status === "APPROVED"  ? <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Add Business
          </Typography>      
         
        </Stack>

                <Grid container>
                    <Grid item xs={12} md={6}>

                    {user && !docId  && 
        <FormikProvider  value={formik}>
        <form style={{padding:0}} onSubmit={formik.handleSubmit}>
      <Stack spacing={3} style={{padding:20}}>
        <Stack direction={{ xs: 'column', }} spacing={2}>
     
          <TextField 
          id="name"
          name="name"
          label="Business/Company Name" 
          fullWidth 
          {...getFieldProps('name')}
          error={ Boolean(errors.name)}
            helperText={touched.name ||  errors.name} 
          />
          
          <TextField fullWidth label="Type of Business" {...getFieldProps('businessType')} select
            error={Boolean(errors.businessType)} helperText={touched.businessType || errors.businessType}          >
                              <MenuItem value={"HOTSPOTS&PLACES"}>Hotspots & Places</MenuItem> 
                              <MenuItem value={"SERVICES"}>Service</MenuItem> 
          </TextField>

           <TextField fullWidth label="Category" {...getFieldProps('category')} select
            error={Boolean(errors.category)} helperText={touched.category || errors.category}
          >
          {values?.businessType === "SERVICES" ?  services?.sort((a, b) => a.category.localeCompare(b.category)).map((c, index)=>(
                            <MenuItem value={c.category} key={index}><Stack>
                                <Typography>{c.category}</Typography>
                            </Stack></MenuItem>
                    )) : vendorCategories?.sort((a, b) => a.category.localeCompare(b.category)).map((c, index)=>(
                            <MenuItem value={c.category} key={index}><Stack>
                                <Typography>{c.category}</Typography>
                            </Stack></MenuItem>
                    ))}
          </TextField>
          <TextField fullWidth label="Description" {...getFieldProps('description')}
            error={Boolean(errors.description)} helperText={touched.description || errors.description}
          />
          <TextField fullWidth  type='number' label="Phone number" {...getFieldProps('phone')}
            error={Boolean(errors.phone || errors.phone)} helperText={touched.phone || errors.phone}
          />


          <TextField fullWidth label="Address" {...getFieldProps('address')} placeholder='e.g 6501, Lilayi Road, Chalala, Lusaka.'
            error={Boolean(errors.address)} helperText={touched.address || errors.address}
          />
              <TextField fullWidth label="Company Type" {...getFieldProps('companyType')} select
            error={Boolean(errors.companyType)} helperText={touched.companyType || errors.companyType}
          >
          
          <MenuItem value={"COMPANY"}>COMPANY</MenuItem>
          <MenuItem value={"BUSINESS_NAME"}>BUSINESS NAME</MenuItem>
                            <MenuItem value={"SELF_EMPLOYED"}>SELF EMPLOYED</MenuItem>
              
              </TextField> 
               {(formik?.values?.companyType === "COMPANY" || formik?.values?.companyType === "BUSINESS_NAME") && 
              <Stack> <TextField  type='number' fullWidth label="PACRA Number" {...getFieldProps('pacra')}
            error={Boolean(errors.pacra && errors.pacra)} helperText={touched.pacra || errors.pacra}
          />
          <Typography>Please note that this is for Authentication purposes only.</Typography>
          </Stack>
               }
          <TextField fullWidth label="Website" {...getFieldProps('website')}
            error={Boolean(errors.website)} helperText={touched.website || errors.website}
          />
          <TextField fullWidth label="Facebook Username" {...getFieldProps('facebook')}
            error={Boolean(errors.facebook)} helperText={touched.facebook || errors.facebook}
          />
          <TextField fullWidth label="Instagram Username" {...getFieldProps('instagram')}
            error={Boolean(errors.instagram)} helperText={touched.instagram || errors.instagram}
          />
          <TextField fullWidth label="X Username" {...getFieldProps('x')}
            error={Boolean(errors.x)} helperText={touched.x && errors.x}
          />
         
         
          <TextField fullWidth label="LinkedIn Username" {...getFieldProps('linkedIn')}
            error={Boolean(errors.linkedIn)} helperText={touched.linkedIn && errors.linkedIn}
          />
          <TextField fullWidth label="TikTok Username" {...getFieldProps('tiktok')}
            error={Boolean(errors.tiktok)} helperText={touched.tiktok && errors.tiktok}
          />
          <TextField fullWidth label="YouTube Username" {...getFieldProps('youtube')}
            error={Boolean(errors.youtube)} helperText={touched.youtube && errors.youtube}
          />



          <TextField fullWidth type='number' label="WhatsApp Business Number" {...getFieldProps('whatsapp')}
            error={Boolean(errors.whatsapp)} helperText={touched.whatsapp || errors.whatsapp}
          />
      
           {provinces && <TextField
            fullWidth select          
            label="Select your province"
            value={province}
            onChange={(event) => setprovince(event.target.value)}
          >
          {provinces?.sort((a, b) => a.prov_name.localeCompare(b.prov_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.prov_name}</MenuItem>
          ))}
          </TextField>}
        
          {province && <TextField
            fullWidth select          
            label="Select your Town"
            value={town}
            onChange={(event) => settown(event.target.value)}
          >
          {towns_?.sort((a, b) => a.town_name.localeCompare(b.town_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.town_name}</MenuItem>
          ))}
          </TextField>}
         
          {town && <TextField
            fullWidth select          
            label="Select your Township"
            value={township}
            onChange={(event) => settownship(event.target.value)}
          >
          {townships_?.sort((a, b) => a.township_name.localeCompare(b.township_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.township_name}</MenuItem>
          ))}
          </TextField>}

         
          <Stack direction={"row"} spacing={3} justifyContent={"space-between"}>
          <TextField fullWidth type='time' label="Opening Time" {...getFieldProps('startTime')}
         inputProps={
           {
            max:"18:00"
            }
          }
            error={Boolean(touched.startTime && errors.startTime)} helperText={touched.startTime && errors.startTime}
          />
             <TextField fullWidth type='time' label="Closing Time" {...getFieldProps('closeTime')}
            error={Boolean(errors.closeTime )} helperText={touched.closeTime || errors.closeTime}
          />
          </Stack>
          <TextField fullWidth   label="Services (separated by commas)" {...getFieldProps('tags')} 
            error={Boolean(errors.tags)} helperText={touched.tags || errors.tags}
          />
          <TextField fullWidth   label="Copy and paste your Google Maps Address" {...getFieldProps('maps')} 
            error={Boolean(errors.maps)} helperText={touched.maps || errors.maps}
          />
         <LoadingButton
        loading={loading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="warning"
          disabled={!formik.isValid}
        >
          Proceed
        </LoadingButton>

          </Stack>
          </Stack>
 
          </form>
          </FormikProvider>
                    }

                    {docId && <Stack>
            <Typography variant="overline">Update Profile Image</Typography>
            <br/>
            <br/>
            <Stack direction={"row"} spacing={3}  >
          {data?.profile_image &&  <img  width={123} src={data?.profile_image} style={{objectFit:"cover", borderRadius:"12px"}} height={123} alt='_profile_image'/>}
          <br/>
          <Stack >

          <input type="file"   onChange={handleImageChange} />
           <br/>
           {uploadProgress > 0 && <CircularProgress fullWidth
      variant="determinate"
      value={uploadProgress}
      sx={{ my: 2 }}
    />}
    <Stack direction="row" spacing={3} justifyContent={"space-between"}>
                  <Button
                  loading={loading}
             color="success" fullWidth
                variant="contained"
                onClick={()=>navigate(-1)}
                  >
                Skip
              </Button>
                  <LoadingButton
                  loading={loading}
             color="warning" fullWidth
                variant="contained"
                onClick={()=>saveProfile()}
                  >
                Complete
              </LoadingButton>
</Stack>
          </Stack>
          </Stack>     
          </Stack>}
          
                    </Grid>
                    </Grid>
                    </Container> : <Typography>
                    You have reached your limit. Check your profile under businesses. It is under pending and will be approved soon.

                    </Typography>
                    }
                    </>
  )
}

export default AddBusiness2