import  {useState, useEffect} from 'react'
import { collection,  onSnapshot, query, doc, getDocs, getDoc } from 'firebase/firestore';
 
import {  db,} from '../firebase';

function useGetDirectory() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Define an asynchronous function to fetch data
    const fetchData = async () => {
      const q = query(collection(db, "business"));
      const querySnapshot = await getDocs(q);
 
      const userDataPromises = querySnapshot.docs.map(async (docSnapshot) => {
        const {userId} = docSnapshot.data();
        const userDocRef = doc(db, "app_users", userId);
        const userDocSnapshot = await getDoc(userDocRef);

        return {
          ...docSnapshot.data(),
          account: userDocSnapshot.data().account,
          id: docSnapshot.id,
        };
      });

      const userData = await Promise.all(userDataPromises);
      setUser(userData);
    };

    // Call the fetchData function
    fetchData().catch(console.error);

    // Cleanup function to unsubscribe from onSnapshot
    return () => {
      // No direct subscription to clean up since we're using getDocs and getDoc
    };
  }, []);

  const sortAccounts = (arr) => {
    const order = { "PRO": 0, "BASIC": 1, "FREE": 2 };
    return arr?.sort((a, b) => order[a.account] - order[b.account]);
};
  return user && sortAccounts(user);
}

export default useGetDirectory