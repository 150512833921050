import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { signInWithCredential, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import * as Yup from 'yup'; 
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment, Button, Typography, Divider, MenuItem, Container, Grid, Card } from '@mui/material';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

import { LoadingButton } from '@mui/lab';
import {db, auth} from "../../firebase"
import useGetUser from '../../hooks/useGetUser';
import provinces from '../../data/provinces.json'
import towns from '../../data/towns.json'
import townships from '../../data/townships.json'


export default function EditProfile() {
 
    const userId = auth.currentUser?.uid
    const user = useGetUser(userId)
  const navigate = useNavigate()
  const [provinces_, setprovinces] = useState([])
  const [towns_, settowns] = useState([])
  const [townships_, settownships] = useState([]) 
  const [province, setprovince] = useState(null)
  const [town, settown] = useState(null)
  const [township, settownship] = useState(null)

  
    const [loading, setloading] = useState(false)
  const RegisterSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Full name required'),
      phoneNumber: Yup.number().required('Last name required'),
      // provider: Yup.string().required('Preferred bus operator is required'),
  });
  const initialValues= {
    fullName: user?.fullName,      
    phoneNumber : user?.phoneNumber
    // provider : user?.provider
  }
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
      validationSchema: RegisterSchema,
      onSubmit: async (values) => {
        setloading(true)
          console.log(values)
          const data = {
            ...values, 
             province : province.prov_name, 
             town : town.town_name, 
             township : township.township_name
          }
          await updateDoc(doc(db, "app_users", userId),data).then(()=>{
            navigate(-1)
            setloading(false)
          }).catch(()=>{
            alert("error updating your profile. Try again later.")
            setloading(false)
          }); 
        } 
  });

  const { errors, touched, handleSubmit,  getFieldProps, values } = formik;

  useEffect(() => {
    getProvinces()
  }, [])
  
  const getProvinces = ()=>{ 
    setprovinces(provinces) 
    console.log(provinces)
  }   
 
  useEffect(() => {
   if(province) {
     getTowns()
   }
  }, [province])
  
  const getTowns = ()=>{ 
      const result = towns.filter((item)=> item.prov_code === province?.code)

    settowns(result)
    console.log(result);
  }

  useEffect(() => {
    if(town){
      getTownShips()
 }
  }, [town])
  
  const getTownShips = async()=>{ 
    const uniqueArray = townships.filter((v,i,a)=>a.findIndex(t=>(t.township_name === v.township_name))===i);
    const result = uniqueArray.filter((item)=> item.town_code === town?.code)

    settownships(result)
     
  }


  console.log(values.province)
  return (
   <>
      <Helmet>
        <title> Home</title>
      </Helmet>

      <Container>
      <Grid container>
  <Grid item xs={12} md={6} lg={6}> 
    <Card sx={{p:3}}> 
        <Typography variant="h6">Edit Profile</Typography>

        {user && 
        <FormikProvider  value={formik}>
    <Form autoComplete="off" noValidate  onSubmit={handleSubmit}>
      <Stack spacing={3} style={{padding:20}}>
        <Stack direction={{ xs: 'column', }} spacing={2}>
          <TextField
            fullWidth
            label="Full name"
            {...getFieldProps('fullName')}
            error={Boolean(touched.fullName && errors.fullName)}
            helperText={touched.fullName && errors.fullName}
          />

          <Stack direction={"row"} justifyContent={"center"} alignItems="center">
          <Typography variant='overline' style={{fontSize:20, marginRight:10}}>+260</Typography>
          <TextField
            fullWidth
            type='number'
            label="Phone Number"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />
          </Stack>
         
      
        </Stack>

        
        {provinces && <TextField
            fullWidth select          
            label="Select your province"
            value={province}
            onChange={(event) => setprovince(event.target.value)}
          >
          {provinces?.sort((a, b) => a.prov_name.localeCompare(b.prov_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.prov_name}</MenuItem>
          ))}
          </TextField>}
        
          {province && <TextField
            fullWidth select          
            label="Select your Town"
            value={town}
            onChange={(event) => settown(event.target.value)}
          >
          {towns_?.sort((a, b) => a.town_name.localeCompare(b.town_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.town_name}</MenuItem>
          ))}
          </TextField>}
         
          {town && <TextField
            fullWidth select          
            label="Select your Township"
            value={township}
            onChange={(event) => settownship(event.target.value)}
          >
          {townships_?.sort((a, b) => a.township_name.localeCompare(b.township_name)).map((p, index)=>(
            <MenuItem key={index} value={p}>  {p.township_name}</MenuItem>
          ))}
          </TextField>}

         
        <LoadingButton
        loading={loading}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="warning"
          
        >
          Update
        </LoadingButton>
 
      </Stack>
    </Form>
    
  </FormikProvider>}
  </Card>
  </Grid></Grid>
      </Container>
    </>
  );
}
