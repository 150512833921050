import { Helmet } from 'react-helmet-async'
import { Container, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import useGetPostsByUserId from '../../hooks/useGetPostsByUserId'
import UserName from '../../_mock/UserName'
import { BlogPostCard } from '../@dashboard/blog'

function UserProfile() {
    const location = useLocation()
    const navigate =useNavigate()
    const data= location.state

     const posts = useGetPostsByUserId(data)
  return (
    <>
    <Helmet>
        <title> Home</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Posts by <UserName id={data} />
          </Typography>         
        </Stack>
 
        <Grid container spacing={3} justifyContent={"center"}>
        
        <Grid item xs={12} md={6}> 
          {posts?.map((post, index) => (
           <BlogPostCard post={post} key={index} />
          ))}
        </Grid>
        </Grid>
      </Container>
      </>
  )
}

export default UserProfile