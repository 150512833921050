import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Stack, Container, Typography, Grid, Dialog, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
 
import useGetAllExploreAlbums from '../hooks/useGetAllExploreAlbums';

// ----------------------------------------------------------------------

export default function Explore() {

const content = useGetAllExploreAlbums()
const [open, setOpen] = useState(false);
const [selectedItem, setselectedItem] = useState(null)
 
console.log(content?.slice(0,3))
const shuffleArray = (array) => {
    // eslint-disable-next-line no-plusplus
    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const navigate = useNavigate()
 // const shuffledQuestions = content && shuffleArray(content);

const handleSelect =(i, name)=>{
 
    setselectedItem({image : i, albumName : name.name, businessId: name.businessId, description : name.description})
    setOpen(true)
}


  return (
   <>
      <Helmet>
        <title>Gallery</title>
      </Helmet>
 
      <Dialog
        fullScreen
        open={open}
        onClose={()=>setOpen(false)} 
      >
      <Grid container >
     
        <Grid item xs={12} md={12}>
        <Stack direction={"row"} sx={{p:3}} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant='body1'>{selectedItem?.albumName}<br/>
        <Typography component='span' sx={{fontSize:12}}>{selectedItem?.description}</Typography><br/>
        <Button  variant="contained" color="success" sx={{fontSize:12}} onClick={()=>navigate(`/dashboard/vendorPage?id=${selectedItem?.businessId}`)}>View Business</Button>
        </Typography>
  
        <Button variant="text" color='warning' size="large" onClick={()=>setOpen(false)}>Close</Button>
        </Stack>
        <img src={selectedItem?.image} alt={selectedItem?.albumName} style={{width:"100%", height:"100vh", objectFit:"contain"}} />
        </Grid>
       
      </Grid>
          
      </Dialog>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Gallery
          </Typography>         
        </Stack>

        {content && <Grid container spacing={0.4}>
            {content.map((c, index)=>(                
                    c.images?.map((i, index)=>(
                        <Grid item xs={4} md={4} key={index} onClick={() => handleSelect(i, c)}>
                             <img src={i} alt={c.name} style={{width:"100%", height:"100%", objectFit:"cover"}} />
                        </Grid>
                    ))
                
            ))}
        </Grid>}
      </Container>
    </>
  );
}
