import { LoadingButton } from '@mui/lab';
import { AppBar, Toolbar, Typography, Button, Stack, TextField, Divider, LinearProgress, CircularProgress, MenuItem } from '@mui/material'
import {  deleteDoc, doc, updateDoc } from 'firebase/firestore';
import {  getStorage, } from 'firebase/storage';
import React, { useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { auth, db } from '../../../firebase'; 
import vendorCategories from '../../../_mock/events';
// eslint-disable-next-line react/prop-types
function EditItem({handleClose, data}) {

    const storage = getStorage()    
    const [images, setImages] = useState([]);
    const [loading, setloading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0);
 
    // const validationSchema = yup.object().shape({
    //   category: yup.string().required('category is required'),
    //   eventTitle: yup.string().required('Event Title is required'),
    //   eventDescription: yup.string().required('Event Description is required'),
    //   date: yup.string().required('Date and Time is required'),
    //   time: yup.string().required('Date and Time is required'),
    //   location: yup.string().required('Location is required'),   
    //   generalAdmission: yup.number().required('General Admission is required'),   
    // });

    const formik = useFormik({
       initialValues : {
          eventTitle: data.eventTitle || "",
          category:  data.category || "",
          eventDescription: data.eventDescription ||  "",
          time: data.time || "",
          date: data.date ||  "",
          location: data.location || "",
          generalAdmission: data.generalAdmission || "",
          vip: data.vip || "",
          preorder: data.preorder ||  "",
          tags : data.tags || "",
          
        },
      enableReinitialize: true,
      onSubmit: async (values) => { 
        console.log(values)
          setloading(true)  
       await updateDoc(doc(db, 'events', data.id), 
          {...values,  updateAt: Date.now()}).then(()=>{
            handleClose()
            console.log("Document updated with ID: ");
          })
      
      },
    });
     
    async function  deleteListing(){
            await deleteDoc(doc(db, "events", data.id)).then(()=>{
                handleClose()
            })
    }
  
 
  return (
    auth.currentUser?.uid && <div>
            
        <Stack spacing={3} alignItems={"center"} p={3} >
            <Typography>Edit Event </Typography>
        <form style={{padding:0}} onSubmit={formik.handleSubmit}>
        <Stack spacing={3} alignItems={"center"} p={3} >
        <TextField fullWidth select   id="category"
            name="category"
            label="Category"
            value={formik.values.category}
        onChange={formik.handleChange}
        error={formik.touched.category && Boolean(formik.errors.category)}
            helperText={formik.touched.category && formik.errors.category}
        >
      {vendorCategories.map((bc, index)=>(
        <MenuItem value={bc} key={index}>{bc}</MenuItem>
      ))}
      </TextField>
          <TextField
            id="eventTitle"
            name="eventTitle"
            label="Event Title"
            fullWidth
            value={formik.values.eventTitle}
            onChange={formik.handleChange}
            error={formik.touched.eventTitle && Boolean(formik.errors.eventTitle)}
            helperText={formik.touched.eventTitle && formik.errors.eventTitle}
          />
          <TextField
            id="eventDescription"
            name="eventDescription"
            label="Event Description"
            fullWidth
            value={formik.values.eventDescription}
            onChange={formik.handleChange}
            error={formik.touched.eventDescription && Boolean(formik.errors.eventDescription)}
            helperText={formik.touched.eventDescription && formik.errors.eventDescription}
          />
          <Stack direction="row"  justifyContent={"space-between"}>

          <TextField
            id="date"
            name="date"
            label="Date"
            fullWidth type='date'
            value={formik.values.date}
            onChange={formik.handleChange}
            error={formik.touched.date && Boolean(formik.errors.date)}
            helperText={formik.touched.date && formik.errors.date}
          />
          <TextField
            id="time"
            name="time"
            label="Time"
            fullWidth type='time'
            value={formik.values.time}
            onChange={formik.handleChange}
            error={formik.touched.time && Boolean(formik.errors.time)}
            helperText={formik.touched.time && formik.errors.time}
          />
          </Stack>
          <TextField
            id="location"
            name="location"
            label="Location"
            fullWidth
            value={formik.values.location}
            onChange={formik.handleChange}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />
              <TextField
      id="tags"
      name="tags"
      label="Hashtags (separated by commas)"
      fullWidth
      value={formik.values.tags}
      onChange={formik.handleChange}
      error={formik.touched.tags && Boolean(formik.errors.tags)}
      helperText={formik.touched.tags && formik.errors.tags}
    />
          <TextField
            id="preorder"
            name="preorder"
            label="Pre Order"
            fullWidth type="number"
            value={formik.values.preorder}
            onChange={formik.handleChange}
            error={formik.touched.preorder && Boolean(formik.errors.preorder)}
            helperText={formik.touched.preorder && formik.errors.preorder}
          />
          <TextField
            id="generalAdmission"
            name="generalAdmission"
            label="General Admission"
            fullWidth type="number"
            value={formik.values.generalAdmission}
            onChange={formik.handleChange}
            error={formik.touched.generalAdmission && Boolean(formik.errors.generalAdmission)}
            helperText={formik.touched.generalAdmission && formik.errors.generalAdmission}
          />
          <TextField
            id="vip"
            name="vip"
            label="VIP"
            fullWidth type="number"
            value={formik.values.vip}
            onChange={formik.handleChange}
            error={formik.touched.vip && Boolean(formik.errors.vip)}
            helperText={formik.touched.vip && formik.errors.vip}
          /> 
    <LoadingButton loading={loading} type="submit" size="large" variant="outlined" color="warning">Update Event</LoadingButton>
         
        </Stack>
      </form>
        </Stack>
        <Divider sx={{my:3}}>Danger Zone</Divider>

        <Stack sx={{p:3}}>
            <Button variant='outlined' color="error" onClick={()=>deleteListing()} >Delete Event</Button>
        </Stack>
    </div>
  )
}
export default EditItem