// @iconify/react axios date-fns formik yup lodash minisearch react-router-dom uuid
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import {getDatabase} from 'firebase/database'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

  const firebaseConfig = {
    apiKey: "AIzaSyAPYpNSGty5zMQyJ7n9Sgr7O6lFtPYb3jo",
    authDomain: "findme-12.firebaseapp.com",
    databaseURL: "https://findme-12-default-rtdb.firebaseio.com",
    projectId: "findme-12",
    storageBucket: "findme-12.appspot.com",
    messagingSenderId: "56541147333",
    appId: "1:56541147333:web:cdb2c1eef521b42d1ac0ac",
    measurementId: "G-KB3DZK0943"
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const realdb = getDatabase(app);
  const analytics = getAnalytics(app);

  export  {db, auth, realdb, onAuthStateChanged}
 